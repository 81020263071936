import { Copyright } from "@/components/copyright";
import { eventBusReceive } from "@/helpers/event-bus";
import { findAndTranslateErrors } from "@/helpers/format";
import { defaultTheme } from "@/theme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Alert, Snackbar } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavigationMenu } from "./navigationMenu";

const drawerWidth: number = 220;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Layout({ children }) {
  const { t } = useTranslation("misc");
  const isIntelligent = useMediaQuery(defaultTheme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(!isIntelligent);
  const [pageName, setPageName] = React.useState<string>("");
  const [rightMenu, setRightMenu] = React.useState<JSX.Element>(<></>);
  const [formSuccess, setFormSuccess] = React.useState<string>("");
  const [formError, setFormError] = React.useState<string>("");
  const [noticeOpen, setNoticeOpen] = React.useState<boolean>(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    eventBusReceive("page-name", (payload) => {
      setPageName(payload);
    });

    eventBusReceive("right-menu", (payload) => {
      setRightMenu(payload);
    });

    eventBusReceive("form-success", (payload) => {
      setNoticeOpen(true);
      setFormSuccess(payload);
    });

    eventBusReceive("form-error", (payload) => {
      const error = payload;
      const displayError: string = findAndTranslateErrors({ error, t });

      setNoticeOpen(true);
      setFormError(displayError);
    });
  }, [t]);

  // const pathLocation = useLocation();

  const handleClose = () => {
    setFormError("");
    setFormSuccess("");
    setNoticeOpen(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={noticeOpen && formSuccess !== ""}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success">
          {formSuccess}
        </Alert>
      </Snackbar>

      <Snackbar
        open={noticeOpen && formError !== ""}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {formError}
        </Alert>
      </Snackbar>
      <Box sx={{ display: "flex" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar variant="dense" sx={{ minHeight: 50, height: 50 }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <ChevronRightIcon />
            </IconButton>
            <Typography
              component="h1"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {pageName}
            </Typography>
            {rightMenu}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
              height: 50,
            }}
            variant="dense"
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <NavigationMenu open={open} />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 1, mb: 1 }}>
            {children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
}
