import { getDeviceInfo } from "@/helpers/device";
import { getParams } from "@/helpers/navigation";
import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const COLLECT_DATA = gql`
  mutation CollectData($eventId: UUID!, $deviceInput: DeviceInput!) {
    CollectData(eventId: $eventId, deviceInput: $deviceInput)
  }
`;

export default function MemberEntrypointQRCode() {
  const [mutationCollectData, newScan] = useMutation(COLLECT_DATA);

  const params = getParams();
  let { eventID } = useParams();
  if (!eventID) eventID = params.get("event_id") || "";
  const dest = params.get("dest") || "";

  // Straight away we collect data
  useEffect(() => {
    const deviceInput = getDeviceInfo();

    mutationCollectData({
      variables: {
        eventId: eventID,
        deviceInput,
      },
    });
  }, []);

  if (newScan.data) {
    window.location.href = dest;
    return <></>;
  }

  // No matter what happens we redirect the person
  if (newScan.error) {
    window.location.href = dest;
    return <></>;
  }

  return <></>;
}
