import * as React from "react";

import { RenderBarChartNumbers } from "@/components/charts/render-bar-chart-numbers";
import { currentPusher } from "@/pusher";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const LIST_EVENTS_TIME_SERIES = gql`
  query GetEventsTimeSeries(
    $startDate: Time!
    $endDate: Time!
    $intervalInMinutes: Int!
  ) {
    GetEventsTimeSeries(
      startDate: $startDate
      endDate: $endDate
      intervalInMinutes: $intervalInMinutes
    ) {
      data {
        timestamp
        eventCount
      }
      totalIntervals
      totalData
    }
  }
`;

export default function Timeline({ dateRange, workspace }) {
  const { t } = useTranslation("workspace");
  const intervalInMinutes = 30;
  const { data, error, loading, refetch } = useQuery(LIST_EVENTS_TIME_SERIES, {
    variables: {
      startDate: dateRange.start,
      endDate: dateRange.end,
      intervalInMinutes,
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (workspace.id) {
      const channel = currentPusher.subscribe(
        `linkbreakers.workspace.${workspace.id}`
      );
      channel.bind("linkbreakers.fct.event-created.0", function (data) {
        // const pushedEvent = JSON.stringify(data);
        // for now, we just refetch the events
        // because we detect an event was effectively added
        refetch();
      });
    }
  }, [workspace]);

  const timeseries = data?.GetEventsTimeSeries;
  const elements = [
    {
      name: t("timeline.scans-label"),
      entry: "eventCount",
      color: "primary",
    },
    // {
    //   name: t("timeline.something-label"),
    //   entry: "somethingCount",
    //   color: "secondary",
    // },
  ];

  return (
    <React.Fragment>
      <RenderBarChartNumbers
        timeseries={timeseries}
        error={error}
        loading={loading}
        elements={elements}
        intervalInMinutes={intervalInMinutes}
        axisName={t("timeline.events-axis")}
      />
    </React.Fragment>
  );
}
