import { Copyright } from "@/components/copyright";
import GlobalSmallLoading from "@/components/global-small-loading";
import { assignPathWith } from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { useQuery } from "@apollo/client";
import GestureIcon from "@mui/icons-material/Gesture";
import LinkIcon from "@mui/icons-material/Link";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GET_LINK } from "./base";

export default function MemberIndex() {
  const { t } = useTranslation("workspace");
  const navigate = useNavigate();
  const { linkID } = useParams();
  const [link, setLink] = useState({ id: null });

  const getLink = useQuery(GET_LINK, {
    variables: { id: linkID },
    fetchPolicy: "no-cache",
  });

  if (getLink.loading) return <GlobalSmallLoading />;
  if (getLink.data) {
    if (link.id === "") setLink(getLink.data.GetLink);
  }
  if (getLink.error) return <NotFound error={getLink.error} />;

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="xs"
        className="bg-white p-3 rounded-xl mt-10 shadow-md"
      >
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <QrCodeIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
            Edit your QR Code
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    assignPathWith(
                      navigate,
                      `/workspace/dashboard/links/${linkID}/edit/base`
                    );
                  }}
                  size="large"
                  sx={{ mt: 2, p: 3, textTransform: "none" }}
                >
                  <LinkIcon />
                  EDIT TRACKING LINK
                  <br />
                  Update base information such as destination, name or short
                  link.
                </Button>
              </Grid>
              <Grid item xs={12} className="relative">
                <Button
                  onClick={() => {
                    assignPathWith(
                      navigate,
                      `/workspace/dashboard/links/${linkID}/edit/design`
                    );
                  }}
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 2, p: 3, textTransform: "none" }}
                >
                  <GestureIcon />
                  DESIGN QR CODE
                  <br />
                  Change the design of your QR Code. Use templates or make it
                  your own.
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
