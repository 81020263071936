import AstroChill from "@/components/astro-chill";
import AstroHelp from "@/components/astro-help";
import { FormError } from "@/components/form";
import GlobalLoading from "@/components/global-loading";
import LoadingButton from "@/components/loading-button";
import PartialLoading from "@/components/partial-loading";
import { getParams, rawURLAssign, rawURLReplace } from "@/helpers/navigation";
import { Workspace } from "@/interfaces";
import { gql, useMutation, useQuery } from "@apollo/client";
import PaymentIcon from "@mui/icons-material/Payment";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const CHECK_SUBSCRIBED_FREQUENCY = 1000;
const WAIT_FOR_CHECK_AFTER_SUCCESS = 2000;

const CREATE_SUBSCRIPTION_CHECKOUT = gql`
  mutation CreateSubscriptionCheckout($afterCheckoutURL: String!) {
    CreateSubscriptionCheckout(afterCheckoutURL: $afterCheckoutURL)
  }
`;

const GET_ORGANIZATION_METRIC = gql`
  query GetWorkspaceMetricTotalMonthlyEvents {
    GetWorkspaceMetric {
      totalMonthlyEvents
    }
  }
`;

const GET_MY_WORKSPACE = gql`
  query MyWorkspaceWithSubscription {
    MyWorkspace {
      id
      subscriptionType
    }
  }
`;

export function AfterCheckout({ setSubscribed }) {
  const { t } = useTranslation("billing");
  const [skip, setSkip] = useState<boolean>(true);
  const getMyselfWorkspace = useQuery(GET_MY_WORKSPACE, {
    skip,
    pollInterval: CHECK_SUBSCRIBED_FREQUENCY,
  });

  useEffect(() => {
    setTimeout(() => {
      setSkip(false);
    }, WAIT_FOR_CHECK_AFTER_SUCCESS);
  }, []);

  useMemo(() => {
    if (getMyselfWorkspace.data) {
      const workspace: Workspace = getMyselfWorkspace.data.MyWorkspace;
      if (workspace.subscriptionType) {
        setSubscribed(true);
      }
    }
  }, [getMyselfWorkspace.data]);

  return (
    <React.Fragment>
      <Grid item xs={10} lg={8} sx={{ mt: 10, mb: 2 }}>
        <Typography component="h1" variant="h3">
          {t("checkout.title-activated")}
        </Typography>
      </Grid>
      <Grid item xs={10} lg={6} sx={{ mt: 4, mb: 2 }}>
        <Grid item xs={6} margin="auto">
          <AstroChill />
        </Grid>
        <Typography sx={{ mt: 2, mb: 2 }}>
          {t("checkout.after-please-wait")}
        </Typography>
        <PartialLoading />
      </Grid>
    </React.Fragment>
  );
}

export function PleaseCheckout() {
  const { t } = useTranslation("billing");

  const [goToCheckout, setGoToCheckout] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<{ totalMonthlyEvents: number }>({
    totalMonthlyEvents: 0,
  });
  const getWorkspaceMetric = useQuery(GET_ORGANIZATION_METRIC);
  const [mutationCreateSubscriptionCheckout, { data, error }] = useMutation(
    CREATE_SUBSCRIPTION_CHECKOUT
  );

  useEffect(() => {
    if (getWorkspaceMetric.data) {
      setMetrics(getWorkspaceMetric.data.GetWorkspaceMetric);
    }
  }, [getWorkspaceMetric.data]);

  useMemo(() => {
    if (goToCheckout) {
      // we want to redirect to this page but remove all search params
      const url = new URL(document.location.href);
      url.search = "";
      const afterCheckoutURL = url.href;
      mutationCreateSubscriptionCheckout({
        variables: { afterCheckoutURL },
      });
    }
  }, [mutationCreateSubscriptionCheckout, goToCheckout]);

  useEffect(() => {
    if (error) {
      setGoToCheckout(false);
    }
  }, [error]);

  if (data) {
    rawURLAssign(data.CreateSubscriptionCheckout);
    return <></>;
  }

  return (
    <React.Fragment>
      <Grid item xs={10} lg={8} sx={{ mt: 10, mb: 2 }}>
        <Typography component="h1" variant="h3">
          {t("checkout.title")}
        </Typography>
      </Grid>
      <Grid item xs={10} lg={4} sx={{ mt: 4, mb: 2 }}>
        <Grid item xs={6} margin="auto">
          <AstroHelp />
        </Grid>
        <Typography sx={{ mt: 2 }}>
          {t("checkout.voluntary-upgrade")}
        </Typography>
        <Grid item sx={{ m: 2 }}>
          {error ? (
            <FormError error={t("checkout.error")} sx={{ mb: 3 }} />
          ) : (
            <></>
          )}
          <LoadingButton
            text={t("checkout.cta")}
            startIcon={<PaymentIcon />}
            onClick={() => {
              setGoToCheckout(true);
            }}
            loading={goToCheckout}
            disabled={goToCheckout}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default function Billing() {
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const params = getParams();
  const success = !!params.get("success");
  // In case we are dealing with success message
  // we don't want to skip the thank you page
  const getMyselfWorkspace = useQuery(GET_MY_WORKSPACE, {
    skip: success,
  });

  useMemo(() => {
    if (getMyselfWorkspace.data) {
      const workspace: Workspace = getMyselfWorkspace.data.MyWorkspace;
      if (workspace.subscriptionType) {
        setSubscribed(true);
      }
    }
  }, [getMyselfWorkspace.data]);

  if (subscribed) {
    rawURLReplace("/");
    return <GlobalLoading />;
  }

  return (
    <Grid container spacing={3}>
      <Stack alignItems="center" textAlign="center">
        {success ? (
          <AfterCheckout setSubscribed={setSubscribed} />
        ) : (
          <PleaseCheckout />
        )}
      </Stack>
    </Grid>
  );
}
