import { Copyright } from "@/components/copyright";
import Avatar from "@mui/material/Avatar";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

export default function PrivacyStatement() {
  const { t, i18n } = useTranslation("legal");

  const legalPaper = <TermsEnglish />;

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="md"
        className="bg-white p-3 rounded-xl mt-10 shadow-md"
      >
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <AccountBalanceIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ align: "center" }}>
            {t("legal.terms-of-service")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        ></Box>
        {legalPaper}
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}

export function TermsEnglish() {
  return (
    <React.Fragment>
      <p>
        <strong>Laurent Pierre Roger Schaffner</strong>{" "}
        (&quot;Linkbreakers&quot;, or “we” and its derivatives) operate
        linkbreakers.com (together with any subdomains, the “Site”) and provide
        our users (each user, “you”) with mobile, and web applications (each an
        “App” and, collectively, the “Apps”). These Terms of Service (the
        “Terms”) are a binding agreement between you and Linkbreakers regarding
        your use of the Site, the Apps, and any related services we may perform
        (the Site, Apps and services together, the “Services”). As used in these
        Terms, a “device” means any computer, phone, tablet, or other computer
        hardware on which Apps run, and the term &quot;Premium mode&quot; means
        a group which has access to paying features.
      </p>
      <p>
        <strong>Ownership and Restrictions</strong>
      </p>
      <p>
        By downloading or using the Apps, these terms will automatically apply
        to you – you should make sure therefore that you read them carefully
        before using the Apps. You are not allowed to copy or modify the Apps,
        any part of the Apps, or our trademarks in any way. You are not allowed
        to attempt to extract the source code of the Apps, and you also should
        not try to translate the Apps into other languages, or make derivative
        versions. The Apps itself, and all the trade marks, copyright, database
        rights and other intellectual property rights related to it, still
        belong to Linkbreakers.
      </p>
      <p>
        <strong>Changes to the Apps and Services</strong>
      </p>
      <p>
        We are committed to ensuring that the Apps are as useful and efficient
        as possible. For that reason, we reserve the right to make changes to
        the Apps or to charge for its services, at any time and for any reason.
        We will never charge you for the Apps or its services without making it
        very clear to you exactly what you are paying for.
      </p>
      <p>
        <strong>Data Privacy and Security</strong>
      </p>
      <p>
        This App stores and processes personal data that you enter, in order to
        provide its Service. It is your responsibility to keep your device and
        access to the Apps secure. We advise against jailbreaking or rooting
        your device, as doing so may compromise your phone&#39;s security and
        the proper functioning of the Apps.
      </p>
      <p>
        <strong>Limitation of Liability</strong>
      </p>
      <p>
        You should be aware that there are certain things that we will not take
        responsibility for. Certain functions of the Apps will require the Apps
        to have an active internet connection. The connection can be Wi-Fi, or
        provided by your mobile network provider, but we cannot take
        responsibility for the Apps not working at full functionality if you
        don’t have access to Wi-Fi, and you don’t have any of your data
        allowance left.
      </p>
      <p>
        If you are using the Apps outside of an area with Wi-Fi, you should
        remember that your terms of the agreement with your mobile network
        provider will still apply. As a result, you may be charged by your
        mobile provider for the cost of data for the duration of the connection
        while accessing the Apps, or other third party charges. In using the
        Apps, you are accepting responsibility for any such charges, including
        roaming data charges if you use the Apps outside of your home territory
        (i.e. region or country) without turning off data roaming. If you are
        not the bill payer for the device on which you are using the Apps,
        please be aware that we assume that you have received permission from
        the bill payer for using the Apps.
      </p>
      <p>
        Along the same lines, we cannot always take responsibility for the way
        you use the Apps i.e. You need to make sure that your device stays
        charged – if it runs out of battery and you can’t turn it on to avail
        the Service, we cannot accept responsibility.
      </p>
      <p>
        With respect to our responsibility for your use of the Apps, when you
        are using the Apps, it is important to bear in mind that although we
        endeavour to ensure that it is updated and correct at all times, we do
        rely on third parties to provide information to us so that we can make
        it available to you. We accept no liability for any loss, direct or
        indirect, you experience as a result of relying wholly on this
        functionality of the Apps.
      </p>
      <p>
        <strong>Subscription</strong>
      </p>
      <p>
        Linkbreakers offer a subscription purchase option which allows you to
        access Premium mode.
      </p>
      <p>
        Payments will be processed through Stripe (&quot;Subscription
        Provider&quot;) from which you can access through the Apps.
      </p>
      <p>
        By purchasing subscription through the Subscription Provider, you agree
        to the following terms of service:
      </p>
      <ul>
        <li>
          Purchases options: Our Apps provides several purchase options, each
          with its own duration and features. The available subscription plans
          will be displayed within the app, along with their respective prices.
        </li>
        <li>
          Free Trial: We may offer a free trial period for new subscribers. The
          duration of the free trial and the specific terms of service will be
          indicated on the In Apps and may vary based on your location.
        </li>
        <li>
          Payment: When you subscribe to our service, you agree to pay the
          applicable subscription fee, as well as any taxes or additional
          charges that may apply. The payment will be charged to your account
          upon confirmation of your purchase.
        </li>
        <li>
          Automatic Renewal: Some purchase options displayed within the app are
          recurring. Subscriptions purchased through the In Apps will
          automatically renew unless you cancel your subscription, and the
          renewal feed will be charged to you by the Subscription Provider.
        </li>
        <li>
          Refunds: No refunds or credits will be provided for partial
          subscription periods. Upon cancellation, you will continue to have
          access to the service until the end of the current subscription
          period.
        </li>
        <li>
          Price: The price of Linkbreakers subscription may vary depending on
          the date of the purchase, the location of the user, local taxes, app
          used, product testing, promotions and more. We reserve the right to
          change the subscription prices at any time. However, any price changes
          will not affect the current subscription period you have already paid
          for. The new prices will apply to subsequent subscription periods.
        </li>
        <li>
          Delays and Disruptions: We shall not be responsible for any delays or
          disruptions in service caused by the Apps or its services, including
          but not limited to billing issues, technical problems, or app updates.
        </li>
        <li>
          Data Privacy: We may collect and process certain personal information
          as described in our Privacy Policy. By purchasing a subscription, you
          consent to the collection and processing of your data in accordance
          with our Privacy Policy.
        </li>
        <li>
          Termination: We reserve the right to terminate or suspend your
          subscription and access to the service if you violate our Terms of
          Service or engage in any unauthorized use of the app.
        </li>
      </ul>
      <p>
        In the unlikely event that we are unable to repair the relevant
        &quot;in-app&quot; purchase or are unable to do so within a reasonable
        period of time and without significant inconvenience to you, we might
        authorise the Subscription Provider, from which you originally
        subscribed, to refund you an amount up to the cost of the relevant
        purchase.
      </p>
      <p>
        <strong>Prohibition of Reselling Services</strong>
      </p>
      <p>
        By using our Apps and subscribing to our services, you agree not to
        resell, transfer, or share your subscription or any part of our services
        to any third party. The subscription you purchase is for your personal
        use only, and you are strictly prohibited from distributing,
        sublicensing, or otherwise making our services available to others,
        whether for commercial purposes or otherwise.
      </p>
      <p>
        We invest significant time and resources in developing and providing our
        services to our valued customers. Unauthorized reselling or distribution
        of our services undermines the integrity of our platform, violates our
        Terms of Service, and may result in legal action.
        <br />
        If we have reasonable grounds to believe that you have engaged in the
        reselling or unauthorized distribution of our services, we reserve the
        right to take appropriate actions, including but not limited to:
      </p>
      <ul>
        <li>
          Termination of Subscription: We may immediately terminate your
          subscription and access to our services without prior notice.
        </li>
        <li>
          Legal Action: We may take legal action to protect our rights and seek
          damages caused by the unauthorized reselling or distribution of our
          services.
        </li>
        <li>
          Account Suspension: We may suspend your account pending an
          investigation into potential violations of this clause.
        </li>
      </ul>
      <p>
        By using our mobile app and subscribing to our services, you acknowledge
        and agree that the services are provided to you for personal use only.
        You further understand that any unauthorized resale, transfer, or
        sharing of our services may result in severe consequences as outlined in
        this section.
      </p>
      <p>
        <strong>Application compatibility</strong>
      </p>
      <p>
        At some point, we may wish to update the Apps. The requirements for
        devices may change, and you will need to download the updates if you
        want to keep using the Apps. We do not promise that it will always
        update the Apps so that it is relevant to you and/or works with the
        Operating System or Browser that you have installed on your device.
        However, you promise to always accept updates to the application when
        offered to you, We may also wish to stop providing the Apps, and may
        terminate use of it at any time without giving notice of termination to
        you. Unless we tell you otherwise, upon any termination, (a) the rights
        and licenses granted to you in these terms will end; (b) you must stop
        using the Apps, and (if needed) delete it from your device.
      </p>
      <p>
        <strong>Changes to This Terms of service</strong>
      </p>
      <p>
        We may update our Terms of service from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Terms of service on this page.
        These changes are effective immediately after they are posted on this
        page.
      </p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or suggestions about our Terms of service, do
        not hesitate to contact us at support@linkbreakers.com
      </p>
    </React.Fragment>
  );
}
