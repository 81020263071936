import AuthGoogleIcon from "@/assets/auth-google.svg";
import AuthMicrosoftIcon from "@/assets/auth-microsoft.svg";
import { FormError, FormErrorComponent, FormSuccess } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import { sleep } from "@/helpers";
import { getDeviceInfo } from "@/helpers/device";
import { graphQLErrorCode } from "@/helpers/format";
import { hasIdentity, setIdentity, setLanguage } from "@/helpers/identity";
import {
  addParamsToPath,
  getAfterAuthPath,
  getParams,
  rawURLAssign,
} from "@/helpers/navigation";
import i18n from "@/i18n";
import { gql, useMutation } from "@apollo/client";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const DEFAULT_AFTER_AUTH_URL = "/";

const MEMBER_SIGNIN = gql`
  mutation MemberSignin(
    $signinMethod: SigninMethod!
    $identifier: String!
    $deviceInput: DeviceInput!
    $password: String
  ) {
    MemberSignin(
      signinMethod: $signinMethod
      identifier: $identifier
      password: $password
      deviceInput: $deviceInput
    ) {
      id
      email
      role
      displayLanguage
      accessToken
      workspaceID
    }
  }
`;

const REQUEST_RESET_PASSWORD = gql`
  mutation RequestPasswordReset(
    $signinMethod: SigninMethod!
    $identifier: String!
  ) {
    RequestPasswordReset(signinMethod: $signinMethod, identifier: $identifier)
  }
`;

// setCredentials means that the client is already signed-in
// But has probably no credentials (anonymous account)
// So it changes the module slightly (simplified and different words)
export function SignInModule({ setCredentials = false }) {
  const { t } = useTranslation("authentication");
  const [success, setSuccess] = useState<string>("");
  const [firebaseError, setFirebaseError] = useState<string>("");
  const [signinLoading, setSigninLoading] = useState<boolean>(true);

  const params = getParams();
  const signinMethod = "EMAIL";

  const [formData, setFormData] = useState({
    email: params.get("email") || "",
  });
  const [password, setPassword] = useState<string>("");

  const [mutationMemberSignIn, { data, loading, error }] =
    useMutation(MEMBER_SIGNIN);
  const [mutationRequestPasswordReset, requestResetPassword] = useMutation(
    REQUEST_RESET_PASSWORD
  );

  useEffect(() => {
    if (data) {
      setLanguage(data.MemberSignin.displayLanguage, i18n);
      setIdentity({
        id: data.MemberSignin.id,
        accessToken: data.MemberSignin.accessToken,
        role: data.MemberSignin.role,
        workspaceID: data.MemberSignin.workspaceID,
      });

      (async () => {
        while (hasIdentity() !== true) await sleep(500);
        // we don't clear the getAfterAuthPath because we may need
        // to have redirection manipulation right after the sign-in, such as set name
        const redirectURL =
          getAfterAuthPath({ clear: false }) || DEFAULT_AFTER_AUTH_URL;

        rawURLAssign(redirectURL);
      })();
    }
  }, [data]);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    provider.addScope("email");
    provider.addScope("profile");
    provider.setCustomParameters({
      login_hint: formData.email,
    });

    const auth = getAuth(firebaseApp);
    signInWithRedirect(auth, provider);
    setSigninLoading(true);
  };

  const microsoftSignIn = () => {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      login_hint: formData.email,
      prompt: "consent",
    });

    const auth = getAuth(firebaseApp);
    signInWithRedirect(auth, provider);
    setSigninLoading(true);
  };

  const handleRedirectResult = async () => {
    const auth = getAuth(firebaseApp);

    try {
      setSigninLoading(true);
      const result = await getRedirectResult(auth);

      if (result) {
        const tokenID = await result.user.getIdToken();
        const deviceInput = getDeviceInfo();

        await mutationMemberSignIn({
          variables: {
            identifier: tokenID,
            signinMethod: "FIREBASE",
            deviceInput,
          },
        });
      } else {
        console.log(
          "No redirect result found. User may not have completed the sign-in."
        );
      }
    } catch (error) {
      setFirebaseError(
        "Something went wrong when trying to sign-in with social buttons. Please try again."
      );
    }
    setSigninLoading(false);
  };

  useEffect(() => {
    handleRedirectResult();
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const identifier = formData.email;
    const variables = { identifier, signinMethod, password };

    const deviceInput = getDeviceInfo();
    variables["deviceInput"] = deviceInput;

    mutationMemberSignIn({
      variables,
    });
  };

  const formCompleted = formData.email.length > 0 && password.length > 0;
  let compError: JSX.Element = <></>;
  let hasCompError: boolean = false;

  if (error) {
    const params = {};
    if (signinMethod === "EMAIL") {
      params["email"] = formData.email;
      params["method"] = "email";
    }

    if (graphQLErrorCode(error, "MEMBER_NOT_FOUND")) {
      hasCompError = true;

      const params = {
        email: formData.email,
      };

      const path = addParamsToPath("/workspace/sign-up", params);

      compError = (
        <>
          This account is not registered yet. <a href={path}>Please sign-up</a>{" "}
        </>
      );
    } else if (graphQLErrorCode(error, "WRONG_PASSWORD")) {
      hasCompError = true;

      let identifier;
      if (signinMethod === "EMAIL") identifier = formData.email;

      const variables = { identifier, signinMethod };

      const resetPassword = () => {
        mutationRequestPasswordReset({
          variables,
        });
        setSuccess(t("sign-in.request-reset-password-successful"));
      };

      compError = (
        <Trans t={t} i18nKey={"sign-in.wrong-password"}>
          text
          <a href="#" onClick={resetPassword}>
            text
          </a>
        </Trans>
      );
    } else if (graphQLErrorCode(error, "SOCIAL_EMAIL_NOT_VERIFIED")) {
      hasCompError = true;

      compError = (
        <>Please use an email that has been verified by the social provider.</>
      );
    } else if (graphQLErrorCode(error, "MEMBER_ALREADY_SIGNED_IN")) {
      hasCompError = true;

      if (setCredentials) {
        compError = <>You have already set your credentials.</>;
      } else {
        compError = <>You are already signed-in.</>;
      }
    }
  }

  const disabled = !formCompleted || loading;

  let title = "Welcome back";
  let subtitle = "";
  if (setCredentials) {
    title = "Don't lose your account!";
    subtitle =
      "To avoid losing your account, please attach your credentials to sign-in again in the future.";
  }

  if (signinLoading) {
    return (
      <Container
        component="main"
        maxWidth="xs"
        className="bg-white p-3 rounded-xl mt-10 shadow-md"
      >
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          <Typography variant="subtitle1" className="m-auto text-center">
            {subtitle}
          </Typography>
          <Box
            component="form"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <PartialLoading />
          </Box>
        </Box>
      </Container>
    );
  }

  const SignUpLink = () => {
    return (
      <div
        style={{
          textAlign: "center",
          paddingLeft: 16,
        }}
        className="text-sm"
      >
        Don't have an account?{" "}
        <a
          href="#"
          onClick={() => {
            const params = {
              email: formData.email,
            };
            const path = addParamsToPath("/workspace/sign-up", params);
            rawURLAssign(path);
          }}
        >
          Sign Up
        </a>
      </div>
    );
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="bg-white p-3 rounded-xl mt-10 shadow-md"
    >
      <Box
        sx={{
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Typography variant="subtitle1" className="m-auto text-center">
          {subtitle}
        </Typography>
        <Box
          component="form"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          {hasCompError ? (
            <FormErrorComponent error={compError} sx={{ mb: 3 }} />
          ) : (
            <FormError
              error={error || requestResetPassword.error || firebaseError}
              sx={{ mb: 1 }}
            />
          )}
          {success ? <FormSuccess message={success} /> : <></>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Fragment>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  autoFocus={!formData.email}
                  value={formData.email}
                  onChange={handleChange}
                  label="Email address"
                  name="email"
                  autoComplete="email"
                />
              </Fragment>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                value={password}
                autoFocus={!!formData.email}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                label={t("sign-in.password")}
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                disabled={disabled}
                sx={{ mt: 0 }}
                text="Continue"
                fullWidth
              />
            </Grid>
            {setCredentials ? (
              <Button
                className="ml-4 mt-3"
                type="submit"
                variant="text"
                size="small"
                fullWidth
                onClick={() => {
                  const params = {
                    skip: "true",
                  };
                  const path = addParamsToPath(
                    "/workspace/dashboard/live",
                    params
                  );
                  rawURLAssign(path);
                }}
              >
                Skip this step
              </Button>
            ) : (
              <></>
            )}
            <Grid container direction="column" className="mt-3">
              {setCredentials ? <></> : <SignUpLink />}
            </Grid>
          </Grid>
        </Box>
        <OrDivider />
        <GoogleContinueButton onClick={googleSignIn} />
        <MicrosoftContinueButton onClick={microsoftSignIn} />
      </Box>
    </Container>
  );
}

export function GoogleContinueButton({ onClick }) {
  return (
    <Box width="100%" className="pt-2">
      <div
        className="bg-white p-2 pl-5 pr-5 border-1 border-solid border-greyinput rounded-lg w-100 cursor-pointer hover:bg-greyish"
        onClick={onClick}
      >
        <img className="align-top" src={AuthGoogleIcon} />
        <span className="ml-5">Continue with Google</span>
      </div>
    </Box>
  );
}

export function MicrosoftContinueButton({ onClick }) {
  return (
    <Box width="100%" className="pt-2">
      <div
        className="bg-white p-2 pl-5 pr-5 border-1 border-solid border-greyinput rounded-lg w-100 cursor-pointer hover:bg-greyish"
        onClick={onClick}
      >
        <img className="align-top" src={AuthMicrosoftIcon} />
        <span className="ml-5">Continue with Microsoft Account</span>
      </div>
    </Box>
  );
}

export function OrDivider() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        my: 2,
      }}
    >
      <Box sx={{ flex: 1, height: "1px", bgcolor: "grey.400" }} />
      <Typography sx={{ mx: 2 }} variant="body2">
        OR
      </Typography>
      <Box sx={{ flex: 1, height: "1px", bgcolor: "grey.400" }} />
    </Box>
  );
}

import PartialLoading from "@/components/partial-loading";
import { firebaseApp } from "@/firebase";
import { Button } from "@mui/material";
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithRedirect,
} from "firebase/auth";

export default function MemberSignIn() {
  return <SignInModule />;
}
