import PartialError from "@/components/partial-error";
import PartialLoading from "@/components/partial-loading";
import { getDateAndTime, getDurationFromString } from "@/helpers/time";
import { MembersMetric } from "@/interfaces";
import { currentPusher } from "@/pusher";
import { gql, useQuery } from "@apollo/client";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const GET_EVENTS_METRIC = gql`
  query GetEventsMetric($startDate: Time!, $endDate: Time!) {
    GetEventsMetric(startDate: $startDate, endDate: $endDate) {
      totalEvents
      firstAt
      lastAt
    }
  }
`;

const paperStyle = {
  p: 2,
  mb: 2,
  display: "flex",
  flexDirection: "column",
};

export function ShowNumericMetric({
  name,
  metric,
  color = "primary",
  unit = "",
}) {
  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric === 0 ? "-" : `${metric}${unit}`}
      </Typography>
    </Paper>
  );
}

export function ShowTextMetric({ name, metric, color = "primary" }) {
  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric === "" ? "-" : `${metric}`}
      </Typography>
    </Paper>
  );
}

export default function Metrics({ dateRange, workspace }) {
  const { t } = useTranslation(["workspace", "misc"]);
  const [membersMetric, setMembersMetric] = React.useState<MembersMetric>({
    totalEvents: 0,
    firstAt: "",
    lastAt: "",
  });

  const { data, error, loading, refetch } = useQuery(GET_EVENTS_METRIC, {
    variables: {
      startDate: dateRange.start,
      endDate: dateRange.end,
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (workspace.id) {
      const channel = currentPusher.subscribe(
        `linkbreakers.workspace.${workspace.id}`
      );
      channel.bind("linkbreakers.fct.event-created.0", function (data) {
        // for now, we just refetch the events
        // because we detect an event was effectively added
        refetch();
      });
    }
  }, [workspace]);

  React.useEffect(() => {
    if (data) {
      setMembersMetric(Object.assign(Object.assign({}, data.GetEventsMetric)));
    }
  }, [data]);

  if (loading) return <PartialLoading />;
  if (error) return <PartialError />;

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <ShowNumericMetric
            name={t("metrics.total-events")}
            metric={membersMetric.totalEvents}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ShowDateTimeMetric
            name={t("metrics.first-at")}
            metric={membersMetric.firstAt}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ShowDateTimeMetric
            name={t("metrics.last-at")}
            metric={membersMetric.lastAt}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export function ShowDurationMetric({ name, metric, color = "primary" }) {
  const { t } = useTranslation("misc");

  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric ? getDurationFromString(metric, true, t) : "-"}
      </Typography>
    </Paper>
  );
}

export function ShowDateMetric({ name, metric, color = "primary" }) {
  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric ? metric.split(".")[0] : "-"}
      </Typography>
    </Paper>
  );
}

export function ShowDateTimeMetric({ name, metric, color = "primary" }) {
  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric ? getDateAndTime(metric) : "-"}
      </Typography>
    </Paper>
  );
}
