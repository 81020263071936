import { Portal } from "@mui/base";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { i18n } from "i18next";
import React from "react";

export function GridWithSearch({
  i18n,
  columns,
  rows,
  rowHeight = 50,
  search = true,
}: {
  i18n: i18n;
  columns: GridColDef[];
  rows: any[];
  rowHeight?: number;
  search?: boolean;
}) {
  const data = {
    columns,
    rows,
  };

  let slots = {};
  if (search) {
    slots = { toolbar: CustomToolbar };
  }

  // NOTE: to put back i18n on data-table, simply add that
  // const dataGridLocale = dataGridlocaleFromI18n(i18n);

  return (
    <Box sx={{ width: 1 }}>
      <DataGrid
        {...data}
        disableColumnMenu // hide column menu on the header
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        rowHeight={rowHeight}
        columns={columns}
        slots={slots}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        // NOTE: to put back i18n on data-table, simply add that
        // localeText={
        //   dataGridLocale.components.MuiDataGrid.defaultProps.localeText
        // }
      />
    </Box>
  );
}

function CustomToolbar(props: any) {
  return (
    <React.Fragment>
      <Portal container={() => document.getElementById("filter-panel")!}>
        <GridToolbarQuickFilter autoComplete="off" />
      </Portal>
      {/* <GridToolbar {...props} /> */}
    </React.Fragment>
  );
}
