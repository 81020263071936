import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";

import * as React from "react";
import { useTranslation } from "react-i18next";

import { gql, useQuery } from "@apollo/client";
import { Badge } from "@mui/material";

const GET_EVENT_DAYS = gql`
  query GetEventDays($month: String!, $timezone: String!) {
    GetEventDays(month: $month, timezone: $timezone)
  }
`;

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  const [selectedDay, setSelectedDay] = React.useState<Dayjs | null>(null);

  React.useEffect(() => {
    if (props.selected) {
      setSelectedDay(day);
    } else {
      setSelectedDay(null);
    }
  }, [props.selected, day]);

  const isHighlighted =
    isSelected && (!selectedDay || !day.isSame(selectedDay, "day"));

  return (
    <Badge key={props.day.toString()} overlap="circular">
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        className={isHighlighted ? "bg-grey text-black" : ""}
      />
    </Badge>
  );
}

export default function Calendar({ defaultCalendar, setCalendar, loading }) {
  const { i18n } = useTranslation("misc");
  // Which month is the calendar on
  const [month, setMonth] = React.useState<string>(
    dayjs(defaultCalendar)?.format("YYYY-MM")
  );
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getEventDays = useQuery(GET_EVENT_DAYS, {
    variables: {
      month,
      timezone,
    },
    skip: !month || !timezone,
  });

  // Highlighted days
  // Are the days in the month where events occurred
  let highlightedDays: number[] = [];
  if (getEventDays.data) {
    highlightedDays = getEventDays.data.GetEventDays;
  }

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        <DateCalendar
          onChange={(value) => setCalendar(value)}
          defaultValue={dayjs(defaultCalendar)}
          // We add one day to avoid timezone issues
          onMonthChange={(value) =>
            setMonth(value.add(1, "day").format("YYYY-MM"))
          }
          disabled={loading}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
            } as any,
          }}
          disableFuture
        />
      </LocalizationProvider>
    </React.Fragment>
  );
}
