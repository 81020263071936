/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query MyWorkspaceForUpgrade {\n    MyWorkspace {\n      id\n      name\n      subscriptionType\n    }\n  }\n": types.MyWorkspaceForUpgradeDocument,
    "\n  mutation ResetPassword($token: String!, $newPassword: String!) {\n    ResetPassword(token: $token, newPassword: $newPassword)\n  }\n": types.ResetPasswordDocument,
    "\n  mutation MemberSignin(\n    $signinMethod: SigninMethod!\n    $identifier: String!\n    $deviceInput: DeviceInput!\n    $password: String\n  ) {\n    MemberSignin(\n      signinMethod: $signinMethod\n      identifier: $identifier\n      password: $password\n      deviceInput: $deviceInput\n    ) {\n      id\n      email\n      role\n      displayLanguage\n      accessToken\n      workspaceID\n    }\n  }\n": types.MemberSigninDocument,
    "\n  mutation RequestPasswordReset(\n    $signinMethod: SigninMethod!\n    $identifier: String!\n  ) {\n    RequestPasswordReset(signinMethod: $signinMethod, identifier: $identifier)\n  }\n": types.RequestPasswordResetDocument,
    "\n  mutation CollectData($eventId: UUID!, $deviceInput: DeviceInput!) {\n    CollectData(eventId: $eventId, deviceInput: $deviceInput)\n  }\n": types.CollectDataDocument,
    "\n  mutation UpdateMyselfName($firstName: String!, $lastName: String!) {\n    UpdateMyself(firstName: $firstName, lastName: $lastName) {\n      id\n    }\n  }\n": types.UpdateMyselfNameDocument,
    "\n  query GetMyselfName {\n    GetMyself {\n      id\n      firstName\n      lastName\n    }\n  }\n": types.GetMyselfNameDocument,
    "\n  mutation CreateBillingSession($returnURL: String!) {\n    CreateBillingSession(returnURL: $returnURL)\n  }\n": types.CreateBillingSessionDocument,
    "\n  query MyWorkspaceWithPrimaryLink {\n    MyWorkspace {\n      id\n      primaryLink {\n        id\n        name\n        destination\n        designType\n        qrcodeSignedURL\n      }\n    }\n  }\n": types.MyWorkspaceWithPrimaryLinkDocument,
    "\n  query GetLink($id: UUID!) {\n    GetLink(id: $id) {\n      id\n      shortID\n      name\n      entrypoint\n      destination\n      designType\n    }\n  }\n": types.GetLinkDocument,
    "\n  mutation UpdateLink($id: UUID!, $destination: String!, $name: String) {\n    UpdateLink(id: $id, destination: $destination, name: $name) {\n      id\n    }\n  }\n": types.UpdateLinkDocument,
    "\n  mutation DeleteLink($id: UUID!) {\n    DeleteLink(id: $id)\n  }\n": types.DeleteLinkDocument,
    "\n  query GetLinkWithDesign($id: UUID!) {\n    GetLink(id: $id) {\n      id\n      shortID\n      destination\n      entrypoint\n      linkDesign {\n        id\n        imageSignedURL\n        imageFormat\n        imageSize\n        dotsOptions {\n          color\n          type\n        }\n        cornersSquareOptions {\n          color\n          type\n        }\n        cornersDotOptions {\n          color\n          type\n        }\n        backgroundOptions {\n          color\n        }\n        shape\n        width\n        height\n        errorCorrectionLevel\n        hideBackgroundDots\n      }\n    }\n  }\n": types.GetLinkWithDesignDocument,
    "\n  mutation UpsertLinkDesign(\n    $linkID: UUID!\n    $linkQRCode: Upload!\n    $linkDesignInput: LinkDesignInput!\n  ) {\n    UpsertLinkDesign(\n      linkID: $linkID\n      linkQRCode: $linkQRCode\n      linkDesignInput: $linkDesignInput\n    ) {\n      id\n    }\n  }\n": types.UpsertLinkDesignDocument,
    "\n  query ListDesignTemplates(\n    $search: String\n    $size: Int\n    $linkDestination: String\n  ) {\n    ListDesignTemplates(\n      search: $search\n      size: $size\n      linkDestination: $linkDestination\n    ) {\n      bestFitID\n      designTemplates {\n        id\n        name\n        slug\n        thumbnailSignedURL\n        builtIn\n        description\n        linkDesign {\n          id\n          imageSignedURL\n          imageFormat\n          imageSize\n          dotsOptions {\n            color\n            type\n          }\n          cornersSquareOptions {\n            color\n            type\n          }\n          cornersDotOptions {\n            color\n            type\n          }\n          backgroundOptions {\n            color\n          }\n          shape\n          width\n          height\n          errorCorrectionLevel\n          hideBackgroundDots\n        }\n      }\n    }\n  }\n": types.ListDesignTemplatesDocument,
    "\n  query ListLinksWithImages {\n    ListLinks {\n      id\n      shortID\n      name\n      slug\n      entrypoint\n      designType\n      destination\n      qrcodeSignedURL\n      customDomain {\n        id\n        name\n      }\n    }\n  }\n": types.ListLinksWithImagesDocument,
    "\n  query MyWorkspace {\n    MyWorkspace {\n      id\n      subscriptionType\n      linkSetting {\n        id\n        dataCollection\n      }\n    }\n  }\n": types.MyWorkspaceDocument,
    "\n  query GetEventDays($month: String!, $timezone: String!) {\n    GetEventDays(month: $month, timezone: $timezone)\n  }\n": types.GetEventDaysDocument,
    "\n  query GetWorkspaceMetricTotals {\n    GetWorkspaceMetric {\n      totalMembers\n      totalEvents\n    }\n  }\n": types.GetWorkspaceMetricTotalsDocument,
    "\n  query GetMyselfWithWorkspaceAndPrimaryLink {\n    GetMyself {\n      id\n      firstName\n      lastName\n      password\n      workspace {\n        id\n        name\n        primaryLink {\n          id\n          designType\n          qrcodeSignedURL\n        }\n      }\n    }\n  }\n": types.GetMyselfWithWorkspaceAndPrimaryLinkDocument,
    "\n  query ListEvents($startDate: Time!, $endDate: Time!) {\n    ListEvents(startDate: $startDate, endDate: $endDate) {\n      id\n      scannedAt\n      action\n      triggeredBy\n      entrypoint\n      destination\n      link {\n        id\n        name\n        destination\n        designType\n      }\n      device {\n        id\n        deviceName\n        deviceBrand\n        deviceType\n        platformName\n        platformVersion\n        browserName\n        browserVersion\n        browserLanguage\n        country\n        region\n        state\n        city\n      }\n    }\n  }\n": types.ListEventsDocument,
    "\n  query GetEventsMetric($startDate: Time!, $endDate: Time!) {\n    GetEventsMetric(startDate: $startDate, endDate: $endDate) {\n      totalEvents\n      firstAt\n      lastAt\n    }\n  }\n": types.GetEventsMetricDocument,
    "\n  query GetEventsTimeSeries(\n    $startDate: Time!\n    $endDate: Time!\n    $intervalInMinutes: Int!\n  ) {\n    GetEventsTimeSeries(\n      startDate: $startDate\n      endDate: $endDate\n      intervalInMinutes: $intervalInMinutes\n    ) {\n      data {\n        timestamp\n        eventCount\n      }\n      totalIntervals\n      totalData\n    }\n  }\n": types.GetEventsTimeSeriesDocument,
    "\n  query GetMemberWithDevices($id: UUID!) {\n    GetMember(id: $id) {\n      id\n      firstName\n      lastName\n      devices {\n        id\n        deviceBrand\n        deviceType\n        deviceName\n        deviceMemory\n        deviceConcurrency\n        GPU\n        screenWidth\n        screenHeight\n        screenRatio\n        browserName\n        browserVersion\n        platformName\n        platformVersion\n        touchScreen\n        networkConnection\n        networkBandwidth\n        networkRTT\n        userAgent\n        IPAddress\n        applicationLanguage\n        timezone\n        country\n        state\n        region\n        city\n        lastUsedAt\n      }\n    }\n  }\n": types.GetMemberWithDevicesDocument,
    "\n  query GetMember($id: UUID!) {\n    GetMember(id: $id) {\n      id\n      firstName\n      lastName\n      email\n      role\n      displayLanguage\n      accessRevokedAt\n    }\n  }\n": types.GetMemberDocument,
    "\n  mutation UpdateMember(\n    $id: UUID!\n    $firstName: String\n    $lastName: String\n    $role: Role!\n    $displayLanguage: String!\n    $email: String\n  ) {\n    UpdateMember(\n      id: $id\n      firstName: $firstName\n      lastName: $lastName\n      role: $role\n      displayLanguage: $displayLanguage\n      email: $email\n    ) {\n      id\n      displayLanguage\n    }\n  }\n": types.UpdateMemberDocument,
    "\n  mutation DeleteMember($id: UUID!) {\n    DeleteMember(id: $id)\n  }\n": types.DeleteMemberDocument,
    "\n  mutation RevokeAccess($id: UUID!) {\n    RevokeAccess(id: $id) {\n      id\n    }\n  }\n": types.RevokeAccessDocument,
    "\n  mutation AllowAccess($id: UUID!) {\n    AllowAccess(id: $id) {\n      id\n    }\n  }\n": types.AllowAccessDocument,
    "\n  mutation ForceSignout($id: UUID!) {\n    ForceSignout(id: $id) {\n      id\n    }\n  }\n": types.ForceSignoutDocument,
    "\n  mutation ImportMember($email: String, $firstName: String, $lastName: String) {\n    ImportMember(email: $email, firstName: $firstName, lastName: $lastName) {\n      id\n    }\n  }\n": types.ImportMemberDocument,
    "\n  query ListMembers {\n    ListMembers {\n      id\n      role\n      updatedAt\n      createdAt\n      firstName\n      lastName\n      accessRevokedAt\n      email\n      lastSigninAt\n      lastKnownIP\n    }\n  }\n": types.ListMembersDocument,
    "\n  query ListLinks {\n    ListLinks {\n      id\n      shortID\n      name\n      entrypoint\n      destination\n    }\n  }\n": types.ListLinksDocument,
    "\n  query GetEventsMetricPerLink(\n    $startDate: Time!\n    $endDate: Time!\n    $linkId: UUID\n  ) {\n    GetEventsMetric(startDate: $startDate, endDate: $endDate, linkId: $linkId) {\n      totalEvents\n      firstAt\n      lastAt\n    }\n  }\n": types.GetEventsMetricPerLinkDocument,
    "\n  query ListEventsFromLink($linkId: UUID!, $startDate: Time!, $endDate: Time!) {\n    ListEvents(linkId: $linkId, startDate: $startDate, endDate: $endDate) {\n      id\n      scannedAt\n      action\n      triggeredBy\n      entrypoint\n      destination\n      link {\n        id\n        name\n        destination\n        designType\n      }\n      device {\n        id\n        deviceName\n        deviceBrand\n        deviceType\n        deviceMemory\n        deviceConcurrency\n        screenWidth\n        screenHeight\n        screenRatio\n        GPU\n        browserName\n        browserVersion\n        browserLanguage\n        platformName\n        platformVersion\n        touchScreen\n        networkConnection\n        networkBandwidth\n        networkRTT\n        userAgent\n        IPAddress\n        applicationLanguage\n        timezone\n        country\n        region\n        state\n        city\n        lastUsedAt\n      }\n    }\n  }\n": types.ListEventsFromLinkDocument,
    "\n  query MyWorkspaceWithSetting {\n    MyWorkspace {\n      id\n      name\n      linkSetting {\n        id\n        dataCollection\n      }\n    }\n  }\n": types.MyWorkspaceWithSettingDocument,
    "\n  mutation UpdateWorkspace($name: String) {\n    UpdateWorkspace(name: $name) {\n      id\n      name\n    }\n  }\n": types.UpdateWorkspaceDocument,
    "\n  mutation UpdateLinkSetting($id: UUID!, $dataCollection: DataCollection) {\n    UpdateLinkSetting(id: $id, dataCollection: $dataCollection) {\n      id\n    }\n  }\n": types.UpdateLinkSettingDocument,
    "\n  mutation CreateCustomDomain($name: String!) {\n    CreateCustomDomain(name: $name) {\n      id\n    }\n  }\n": types.CreateCustomDomainDocument,
    "\n  mutation DeleteCustomDomain($id: UUID!) {\n    DeleteCustomDomain(id: $id)\n  }\n": types.DeleteCustomDomainDocument,
    "\n  mutation VerifyCustomDomain($id: UUID!) {\n    VerifyCustomDomain(id: $id) {\n      id\n      verified\n    }\n  }\n": types.VerifyCustomDomainDocument,
    "\n  query ListeCustomDomains {\n    ListCustomDomains {\n      id\n      name\n      verified\n      lastCheckedAt\n    }\n  }\n": types.ListeCustomDomainsDocument,
    "\n  mutation CreateSupportTicket($body: String!) {\n    CreateSupportTicket(body: $body)\n  }\n": types.CreateSupportTicketDocument,
    "\n  mutation CreateLink(\n    $destination: String!\n    $name: String\n    $slug: String\n    $customDomainID: UUID\n  ) {\n    CreateLink(\n      destination: $destination\n      name: $name\n      slug: $slug\n      customDomainID: $customDomainID\n    ) {\n      id\n    }\n  }\n": types.CreateLinkDocument,
    "\n  query CheckSlug($slug: String!, $customDomainID: UUID) {\n    CheckSlug(slug: $slug, customDomainID: $customDomainID)\n  }\n": types.CheckSlugDocument,
    "\n  mutation WorkspaceSignup(\n    $identifier: String!\n    $signupMethod: SignupMethod!\n    $password: String\n    $workspaceName: String\n    $originInput: OriginInput!\n    $deviceInput: DeviceInput!\n  ) {\n    WorkspaceSignup(\n      identifier: $identifier\n      signupMethod: $signupMethod\n      password: $password\n      workspaceName: $workspaceName\n      originInput: $originInput\n      deviceInput: $deviceInput\n    ) {\n      id\n      email\n      role\n      accessToken\n      workspace {\n        id\n      }\n    }\n  }\n": types.WorkspaceSignupDocument,
    "\n  mutation WorkspaceQuickSignup(\n    $linkDestination: String!\n    $originInput: OriginInput!\n    $deviceInput: DeviceInput!\n    $email: String\n    $linkDesignID: UUID\n  ) {\n    WorkspaceQuickSignup(\n      linkDestination: $linkDestination\n      originInput: $originInput\n      deviceInput: $deviceInput\n      email: $email\n      linkDesignID: $linkDesignID\n    ) {\n      id\n      role\n      accessToken\n      workspace {\n        id\n        primaryLink {\n          id\n        }\n      }\n    }\n  }\n": types.WorkspaceQuickSignupDocument,
    "\n  mutation CreateSubscriptionCheckout($afterCheckoutURL: String!) {\n    CreateSubscriptionCheckout(afterCheckoutURL: $afterCheckoutURL)\n  }\n": types.CreateSubscriptionCheckoutDocument,
    "\n  query GetWorkspaceMetricTotalMonthlyEvents {\n    GetWorkspaceMetric {\n      totalMonthlyEvents\n    }\n  }\n": types.GetWorkspaceMetricTotalMonthlyEventsDocument,
    "\n  query MyWorkspaceWithSubscription {\n    MyWorkspace {\n      id\n      subscriptionType\n    }\n  }\n": types.MyWorkspaceWithSubscriptionDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyWorkspaceForUpgrade {\n    MyWorkspace {\n      id\n      name\n      subscriptionType\n    }\n  }\n"): (typeof documents)["\n  query MyWorkspaceForUpgrade {\n    MyWorkspace {\n      id\n      name\n      subscriptionType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPassword($token: String!, $newPassword: String!) {\n    ResetPassword(token: $token, newPassword: $newPassword)\n  }\n"): (typeof documents)["\n  mutation ResetPassword($token: String!, $newPassword: String!) {\n    ResetPassword(token: $token, newPassword: $newPassword)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MemberSignin(\n    $signinMethod: SigninMethod!\n    $identifier: String!\n    $deviceInput: DeviceInput!\n    $password: String\n  ) {\n    MemberSignin(\n      signinMethod: $signinMethod\n      identifier: $identifier\n      password: $password\n      deviceInput: $deviceInput\n    ) {\n      id\n      email\n      role\n      displayLanguage\n      accessToken\n      workspaceID\n    }\n  }\n"): (typeof documents)["\n  mutation MemberSignin(\n    $signinMethod: SigninMethod!\n    $identifier: String!\n    $deviceInput: DeviceInput!\n    $password: String\n  ) {\n    MemberSignin(\n      signinMethod: $signinMethod\n      identifier: $identifier\n      password: $password\n      deviceInput: $deviceInput\n    ) {\n      id\n      email\n      role\n      displayLanguage\n      accessToken\n      workspaceID\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestPasswordReset(\n    $signinMethod: SigninMethod!\n    $identifier: String!\n  ) {\n    RequestPasswordReset(signinMethod: $signinMethod, identifier: $identifier)\n  }\n"): (typeof documents)["\n  mutation RequestPasswordReset(\n    $signinMethod: SigninMethod!\n    $identifier: String!\n  ) {\n    RequestPasswordReset(signinMethod: $signinMethod, identifier: $identifier)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CollectData($eventId: UUID!, $deviceInput: DeviceInput!) {\n    CollectData(eventId: $eventId, deviceInput: $deviceInput)\n  }\n"): (typeof documents)["\n  mutation CollectData($eventId: UUID!, $deviceInput: DeviceInput!) {\n    CollectData(eventId: $eventId, deviceInput: $deviceInput)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMyselfName($firstName: String!, $lastName: String!) {\n    UpdateMyself(firstName: $firstName, lastName: $lastName) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMyselfName($firstName: String!, $lastName: String!) {\n    UpdateMyself(firstName: $firstName, lastName: $lastName) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMyselfName {\n    GetMyself {\n      id\n      firstName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  query GetMyselfName {\n    GetMyself {\n      id\n      firstName\n      lastName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBillingSession($returnURL: String!) {\n    CreateBillingSession(returnURL: $returnURL)\n  }\n"): (typeof documents)["\n  mutation CreateBillingSession($returnURL: String!) {\n    CreateBillingSession(returnURL: $returnURL)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyWorkspaceWithPrimaryLink {\n    MyWorkspace {\n      id\n      primaryLink {\n        id\n        name\n        destination\n        designType\n        qrcodeSignedURL\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyWorkspaceWithPrimaryLink {\n    MyWorkspace {\n      id\n      primaryLink {\n        id\n        name\n        destination\n        designType\n        qrcodeSignedURL\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetLink($id: UUID!) {\n    GetLink(id: $id) {\n      id\n      shortID\n      name\n      entrypoint\n      destination\n      designType\n    }\n  }\n"): (typeof documents)["\n  query GetLink($id: UUID!) {\n    GetLink(id: $id) {\n      id\n      shortID\n      name\n      entrypoint\n      destination\n      designType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateLink($id: UUID!, $destination: String!, $name: String) {\n    UpdateLink(id: $id, destination: $destination, name: $name) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateLink($id: UUID!, $destination: String!, $name: String) {\n    UpdateLink(id: $id, destination: $destination, name: $name) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteLink($id: UUID!) {\n    DeleteLink(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteLink($id: UUID!) {\n    DeleteLink(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetLinkWithDesign($id: UUID!) {\n    GetLink(id: $id) {\n      id\n      shortID\n      destination\n      entrypoint\n      linkDesign {\n        id\n        imageSignedURL\n        imageFormat\n        imageSize\n        dotsOptions {\n          color\n          type\n        }\n        cornersSquareOptions {\n          color\n          type\n        }\n        cornersDotOptions {\n          color\n          type\n        }\n        backgroundOptions {\n          color\n        }\n        shape\n        width\n        height\n        errorCorrectionLevel\n        hideBackgroundDots\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetLinkWithDesign($id: UUID!) {\n    GetLink(id: $id) {\n      id\n      shortID\n      destination\n      entrypoint\n      linkDesign {\n        id\n        imageSignedURL\n        imageFormat\n        imageSize\n        dotsOptions {\n          color\n          type\n        }\n        cornersSquareOptions {\n          color\n          type\n        }\n        cornersDotOptions {\n          color\n          type\n        }\n        backgroundOptions {\n          color\n        }\n        shape\n        width\n        height\n        errorCorrectionLevel\n        hideBackgroundDots\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpsertLinkDesign(\n    $linkID: UUID!\n    $linkQRCode: Upload!\n    $linkDesignInput: LinkDesignInput!\n  ) {\n    UpsertLinkDesign(\n      linkID: $linkID\n      linkQRCode: $linkQRCode\n      linkDesignInput: $linkDesignInput\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertLinkDesign(\n    $linkID: UUID!\n    $linkQRCode: Upload!\n    $linkDesignInput: LinkDesignInput!\n  ) {\n    UpsertLinkDesign(\n      linkID: $linkID\n      linkQRCode: $linkQRCode\n      linkDesignInput: $linkDesignInput\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListDesignTemplates(\n    $search: String\n    $size: Int\n    $linkDestination: String\n  ) {\n    ListDesignTemplates(\n      search: $search\n      size: $size\n      linkDestination: $linkDestination\n    ) {\n      bestFitID\n      designTemplates {\n        id\n        name\n        slug\n        thumbnailSignedURL\n        builtIn\n        description\n        linkDesign {\n          id\n          imageSignedURL\n          imageFormat\n          imageSize\n          dotsOptions {\n            color\n            type\n          }\n          cornersSquareOptions {\n            color\n            type\n          }\n          cornersDotOptions {\n            color\n            type\n          }\n          backgroundOptions {\n            color\n          }\n          shape\n          width\n          height\n          errorCorrectionLevel\n          hideBackgroundDots\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListDesignTemplates(\n    $search: String\n    $size: Int\n    $linkDestination: String\n  ) {\n    ListDesignTemplates(\n      search: $search\n      size: $size\n      linkDestination: $linkDestination\n    ) {\n      bestFitID\n      designTemplates {\n        id\n        name\n        slug\n        thumbnailSignedURL\n        builtIn\n        description\n        linkDesign {\n          id\n          imageSignedURL\n          imageFormat\n          imageSize\n          dotsOptions {\n            color\n            type\n          }\n          cornersSquareOptions {\n            color\n            type\n          }\n          cornersDotOptions {\n            color\n            type\n          }\n          backgroundOptions {\n            color\n          }\n          shape\n          width\n          height\n          errorCorrectionLevel\n          hideBackgroundDots\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListLinksWithImages {\n    ListLinks {\n      id\n      shortID\n      name\n      slug\n      entrypoint\n      designType\n      destination\n      qrcodeSignedURL\n      customDomain {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListLinksWithImages {\n    ListLinks {\n      id\n      shortID\n      name\n      slug\n      entrypoint\n      designType\n      destination\n      qrcodeSignedURL\n      customDomain {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyWorkspace {\n    MyWorkspace {\n      id\n      subscriptionType\n      linkSetting {\n        id\n        dataCollection\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyWorkspace {\n    MyWorkspace {\n      id\n      subscriptionType\n      linkSetting {\n        id\n        dataCollection\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetEventDays($month: String!, $timezone: String!) {\n    GetEventDays(month: $month, timezone: $timezone)\n  }\n"): (typeof documents)["\n  query GetEventDays($month: String!, $timezone: String!) {\n    GetEventDays(month: $month, timezone: $timezone)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetWorkspaceMetricTotals {\n    GetWorkspaceMetric {\n      totalMembers\n      totalEvents\n    }\n  }\n"): (typeof documents)["\n  query GetWorkspaceMetricTotals {\n    GetWorkspaceMetric {\n      totalMembers\n      totalEvents\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMyselfWithWorkspaceAndPrimaryLink {\n    GetMyself {\n      id\n      firstName\n      lastName\n      password\n      workspace {\n        id\n        name\n        primaryLink {\n          id\n          designType\n          qrcodeSignedURL\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMyselfWithWorkspaceAndPrimaryLink {\n    GetMyself {\n      id\n      firstName\n      lastName\n      password\n      workspace {\n        id\n        name\n        primaryLink {\n          id\n          designType\n          qrcodeSignedURL\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListEvents($startDate: Time!, $endDate: Time!) {\n    ListEvents(startDate: $startDate, endDate: $endDate) {\n      id\n      scannedAt\n      action\n      triggeredBy\n      entrypoint\n      destination\n      link {\n        id\n        name\n        destination\n        designType\n      }\n      device {\n        id\n        deviceName\n        deviceBrand\n        deviceType\n        platformName\n        platformVersion\n        browserName\n        browserVersion\n        browserLanguage\n        country\n        region\n        state\n        city\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListEvents($startDate: Time!, $endDate: Time!) {\n    ListEvents(startDate: $startDate, endDate: $endDate) {\n      id\n      scannedAt\n      action\n      triggeredBy\n      entrypoint\n      destination\n      link {\n        id\n        name\n        destination\n        designType\n      }\n      device {\n        id\n        deviceName\n        deviceBrand\n        deviceType\n        platformName\n        platformVersion\n        browserName\n        browserVersion\n        browserLanguage\n        country\n        region\n        state\n        city\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetEventsMetric($startDate: Time!, $endDate: Time!) {\n    GetEventsMetric(startDate: $startDate, endDate: $endDate) {\n      totalEvents\n      firstAt\n      lastAt\n    }\n  }\n"): (typeof documents)["\n  query GetEventsMetric($startDate: Time!, $endDate: Time!) {\n    GetEventsMetric(startDate: $startDate, endDate: $endDate) {\n      totalEvents\n      firstAt\n      lastAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetEventsTimeSeries(\n    $startDate: Time!\n    $endDate: Time!\n    $intervalInMinutes: Int!\n  ) {\n    GetEventsTimeSeries(\n      startDate: $startDate\n      endDate: $endDate\n      intervalInMinutes: $intervalInMinutes\n    ) {\n      data {\n        timestamp\n        eventCount\n      }\n      totalIntervals\n      totalData\n    }\n  }\n"): (typeof documents)["\n  query GetEventsTimeSeries(\n    $startDate: Time!\n    $endDate: Time!\n    $intervalInMinutes: Int!\n  ) {\n    GetEventsTimeSeries(\n      startDate: $startDate\n      endDate: $endDate\n      intervalInMinutes: $intervalInMinutes\n    ) {\n      data {\n        timestamp\n        eventCount\n      }\n      totalIntervals\n      totalData\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMemberWithDevices($id: UUID!) {\n    GetMember(id: $id) {\n      id\n      firstName\n      lastName\n      devices {\n        id\n        deviceBrand\n        deviceType\n        deviceName\n        deviceMemory\n        deviceConcurrency\n        GPU\n        screenWidth\n        screenHeight\n        screenRatio\n        browserName\n        browserVersion\n        platformName\n        platformVersion\n        touchScreen\n        networkConnection\n        networkBandwidth\n        networkRTT\n        userAgent\n        IPAddress\n        applicationLanguage\n        timezone\n        country\n        state\n        region\n        city\n        lastUsedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMemberWithDevices($id: UUID!) {\n    GetMember(id: $id) {\n      id\n      firstName\n      lastName\n      devices {\n        id\n        deviceBrand\n        deviceType\n        deviceName\n        deviceMemory\n        deviceConcurrency\n        GPU\n        screenWidth\n        screenHeight\n        screenRatio\n        browserName\n        browserVersion\n        platformName\n        platformVersion\n        touchScreen\n        networkConnection\n        networkBandwidth\n        networkRTT\n        userAgent\n        IPAddress\n        applicationLanguage\n        timezone\n        country\n        state\n        region\n        city\n        lastUsedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMember($id: UUID!) {\n    GetMember(id: $id) {\n      id\n      firstName\n      lastName\n      email\n      role\n      displayLanguage\n      accessRevokedAt\n    }\n  }\n"): (typeof documents)["\n  query GetMember($id: UUID!) {\n    GetMember(id: $id) {\n      id\n      firstName\n      lastName\n      email\n      role\n      displayLanguage\n      accessRevokedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMember(\n    $id: UUID!\n    $firstName: String\n    $lastName: String\n    $role: Role!\n    $displayLanguage: String!\n    $email: String\n  ) {\n    UpdateMember(\n      id: $id\n      firstName: $firstName\n      lastName: $lastName\n      role: $role\n      displayLanguage: $displayLanguage\n      email: $email\n    ) {\n      id\n      displayLanguage\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMember(\n    $id: UUID!\n    $firstName: String\n    $lastName: String\n    $role: Role!\n    $displayLanguage: String!\n    $email: String\n  ) {\n    UpdateMember(\n      id: $id\n      firstName: $firstName\n      lastName: $lastName\n      role: $role\n      displayLanguage: $displayLanguage\n      email: $email\n    ) {\n      id\n      displayLanguage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMember($id: UUID!) {\n    DeleteMember(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteMember($id: UUID!) {\n    DeleteMember(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RevokeAccess($id: UUID!) {\n    RevokeAccess(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation RevokeAccess($id: UUID!) {\n    RevokeAccess(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AllowAccess($id: UUID!) {\n    AllowAccess(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AllowAccess($id: UUID!) {\n    AllowAccess(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ForceSignout($id: UUID!) {\n    ForceSignout(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ForceSignout($id: UUID!) {\n    ForceSignout(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ImportMember($email: String, $firstName: String, $lastName: String) {\n    ImportMember(email: $email, firstName: $firstName, lastName: $lastName) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ImportMember($email: String, $firstName: String, $lastName: String) {\n    ImportMember(email: $email, firstName: $firstName, lastName: $lastName) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListMembers {\n    ListMembers {\n      id\n      role\n      updatedAt\n      createdAt\n      firstName\n      lastName\n      accessRevokedAt\n      email\n      lastSigninAt\n      lastKnownIP\n    }\n  }\n"): (typeof documents)["\n  query ListMembers {\n    ListMembers {\n      id\n      role\n      updatedAt\n      createdAt\n      firstName\n      lastName\n      accessRevokedAt\n      email\n      lastSigninAt\n      lastKnownIP\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListLinks {\n    ListLinks {\n      id\n      shortID\n      name\n      entrypoint\n      destination\n    }\n  }\n"): (typeof documents)["\n  query ListLinks {\n    ListLinks {\n      id\n      shortID\n      name\n      entrypoint\n      destination\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetEventsMetricPerLink(\n    $startDate: Time!\n    $endDate: Time!\n    $linkId: UUID\n  ) {\n    GetEventsMetric(startDate: $startDate, endDate: $endDate, linkId: $linkId) {\n      totalEvents\n      firstAt\n      lastAt\n    }\n  }\n"): (typeof documents)["\n  query GetEventsMetricPerLink(\n    $startDate: Time!\n    $endDate: Time!\n    $linkId: UUID\n  ) {\n    GetEventsMetric(startDate: $startDate, endDate: $endDate, linkId: $linkId) {\n      totalEvents\n      firstAt\n      lastAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListEventsFromLink($linkId: UUID!, $startDate: Time!, $endDate: Time!) {\n    ListEvents(linkId: $linkId, startDate: $startDate, endDate: $endDate) {\n      id\n      scannedAt\n      action\n      triggeredBy\n      entrypoint\n      destination\n      link {\n        id\n        name\n        destination\n        designType\n      }\n      device {\n        id\n        deviceName\n        deviceBrand\n        deviceType\n        deviceMemory\n        deviceConcurrency\n        screenWidth\n        screenHeight\n        screenRatio\n        GPU\n        browserName\n        browserVersion\n        browserLanguage\n        platformName\n        platformVersion\n        touchScreen\n        networkConnection\n        networkBandwidth\n        networkRTT\n        userAgent\n        IPAddress\n        applicationLanguage\n        timezone\n        country\n        region\n        state\n        city\n        lastUsedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListEventsFromLink($linkId: UUID!, $startDate: Time!, $endDate: Time!) {\n    ListEvents(linkId: $linkId, startDate: $startDate, endDate: $endDate) {\n      id\n      scannedAt\n      action\n      triggeredBy\n      entrypoint\n      destination\n      link {\n        id\n        name\n        destination\n        designType\n      }\n      device {\n        id\n        deviceName\n        deviceBrand\n        deviceType\n        deviceMemory\n        deviceConcurrency\n        screenWidth\n        screenHeight\n        screenRatio\n        GPU\n        browserName\n        browserVersion\n        browserLanguage\n        platformName\n        platformVersion\n        touchScreen\n        networkConnection\n        networkBandwidth\n        networkRTT\n        userAgent\n        IPAddress\n        applicationLanguage\n        timezone\n        country\n        region\n        state\n        city\n        lastUsedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyWorkspaceWithSetting {\n    MyWorkspace {\n      id\n      name\n      linkSetting {\n        id\n        dataCollection\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyWorkspaceWithSetting {\n    MyWorkspace {\n      id\n      name\n      linkSetting {\n        id\n        dataCollection\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateWorkspace($name: String) {\n    UpdateWorkspace(name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateWorkspace($name: String) {\n    UpdateWorkspace(name: $name) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateLinkSetting($id: UUID!, $dataCollection: DataCollection) {\n    UpdateLinkSetting(id: $id, dataCollection: $dataCollection) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateLinkSetting($id: UUID!, $dataCollection: DataCollection) {\n    UpdateLinkSetting(id: $id, dataCollection: $dataCollection) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCustomDomain($name: String!) {\n    CreateCustomDomain(name: $name) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCustomDomain($name: String!) {\n    CreateCustomDomain(name: $name) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCustomDomain($id: UUID!) {\n    DeleteCustomDomain(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteCustomDomain($id: UUID!) {\n    DeleteCustomDomain(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation VerifyCustomDomain($id: UUID!) {\n    VerifyCustomDomain(id: $id) {\n      id\n      verified\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyCustomDomain($id: UUID!) {\n    VerifyCustomDomain(id: $id) {\n      id\n      verified\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListeCustomDomains {\n    ListCustomDomains {\n      id\n      name\n      verified\n      lastCheckedAt\n    }\n  }\n"): (typeof documents)["\n  query ListeCustomDomains {\n    ListCustomDomains {\n      id\n      name\n      verified\n      lastCheckedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSupportTicket($body: String!) {\n    CreateSupportTicket(body: $body)\n  }\n"): (typeof documents)["\n  mutation CreateSupportTicket($body: String!) {\n    CreateSupportTicket(body: $body)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateLink(\n    $destination: String!\n    $name: String\n    $slug: String\n    $customDomainID: UUID\n  ) {\n    CreateLink(\n      destination: $destination\n      name: $name\n      slug: $slug\n      customDomainID: $customDomainID\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateLink(\n    $destination: String!\n    $name: String\n    $slug: String\n    $customDomainID: UUID\n  ) {\n    CreateLink(\n      destination: $destination\n      name: $name\n      slug: $slug\n      customDomainID: $customDomainID\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CheckSlug($slug: String!, $customDomainID: UUID) {\n    CheckSlug(slug: $slug, customDomainID: $customDomainID)\n  }\n"): (typeof documents)["\n  query CheckSlug($slug: String!, $customDomainID: UUID) {\n    CheckSlug(slug: $slug, customDomainID: $customDomainID)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation WorkspaceSignup(\n    $identifier: String!\n    $signupMethod: SignupMethod!\n    $password: String\n    $workspaceName: String\n    $originInput: OriginInput!\n    $deviceInput: DeviceInput!\n  ) {\n    WorkspaceSignup(\n      identifier: $identifier\n      signupMethod: $signupMethod\n      password: $password\n      workspaceName: $workspaceName\n      originInput: $originInput\n      deviceInput: $deviceInput\n    ) {\n      id\n      email\n      role\n      accessToken\n      workspace {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation WorkspaceSignup(\n    $identifier: String!\n    $signupMethod: SignupMethod!\n    $password: String\n    $workspaceName: String\n    $originInput: OriginInput!\n    $deviceInput: DeviceInput!\n  ) {\n    WorkspaceSignup(\n      identifier: $identifier\n      signupMethod: $signupMethod\n      password: $password\n      workspaceName: $workspaceName\n      originInput: $originInput\n      deviceInput: $deviceInput\n    ) {\n      id\n      email\n      role\n      accessToken\n      workspace {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation WorkspaceQuickSignup(\n    $linkDestination: String!\n    $originInput: OriginInput!\n    $deviceInput: DeviceInput!\n    $email: String\n    $linkDesignID: UUID\n  ) {\n    WorkspaceQuickSignup(\n      linkDestination: $linkDestination\n      originInput: $originInput\n      deviceInput: $deviceInput\n      email: $email\n      linkDesignID: $linkDesignID\n    ) {\n      id\n      role\n      accessToken\n      workspace {\n        id\n        primaryLink {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation WorkspaceQuickSignup(\n    $linkDestination: String!\n    $originInput: OriginInput!\n    $deviceInput: DeviceInput!\n    $email: String\n    $linkDesignID: UUID\n  ) {\n    WorkspaceQuickSignup(\n      linkDestination: $linkDestination\n      originInput: $originInput\n      deviceInput: $deviceInput\n      email: $email\n      linkDesignID: $linkDesignID\n    ) {\n      id\n      role\n      accessToken\n      workspace {\n        id\n        primaryLink {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSubscriptionCheckout($afterCheckoutURL: String!) {\n    CreateSubscriptionCheckout(afterCheckoutURL: $afterCheckoutURL)\n  }\n"): (typeof documents)["\n  mutation CreateSubscriptionCheckout($afterCheckoutURL: String!) {\n    CreateSubscriptionCheckout(afterCheckoutURL: $afterCheckoutURL)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetWorkspaceMetricTotalMonthlyEvents {\n    GetWorkspaceMetric {\n      totalMonthlyEvents\n    }\n  }\n"): (typeof documents)["\n  query GetWorkspaceMetricTotalMonthlyEvents {\n    GetWorkspaceMetric {\n      totalMonthlyEvents\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyWorkspaceWithSubscription {\n    MyWorkspace {\n      id\n      subscriptionType\n    }\n  }\n"): (typeof documents)["\n  query MyWorkspaceWithSubscription {\n    MyWorkspace {\n      id\n      subscriptionType\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;