import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { UpgradeButton } from "@/components/upgrade-button";
import { graphql } from "@/generated/graphql";
import {
  ListLinksQuery,
  ListLinksWithImagesQuery,
  MyWorkspaceQuery,
} from "@/generated/graphql/graphql";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { eventBusEmit } from "@/helpers/event-bus";
import { getRole } from "@/helpers/identity";
import { assignPathWith } from "@/helpers/navigation";
import { useQuery } from "@apollo/client";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Title from "../components/title";
import { CustomDomains, LinkSettings } from "../settings";
import LinksList from "./list";

const LIST_LINKS_WITH_IMAGES = graphql(`
  query ListLinksWithImages {
    ListLinks {
      id
      shortID
      name
      slug
      entrypoint
      designType
      destination
      qrcodeSignedURL
      customDomain {
        id
        name
      }
    }
  }
`);

const GET_MY_WORKSPACE = graphql(`
  query MyWorkspace {
    MyWorkspace {
      id
      subscriptionType
      linkSetting {
        id
        dataCollection
      }
    }
  }
`);

export default function LinksQRCode() {
  const { t } = useTranslation(["workspace", "misc"]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [updateLinkSetting, setUpdateLinkSetting] = useState<boolean>(false);
  const navigate = useNavigate();

  const [workspace, setWorkspace] = useState<MyWorkspaceQuery["MyWorkspace"]>({
    id: null,
  });
  const [links, setLinks] = useState<ListLinksQuery["ListLinks"]>([]);
  const getMyWorkspace = useQuery<MyWorkspaceQuery>(GET_MY_WORKSPACE, {
    notifyOnNetworkStatusChange: true,
  });
  const listLinks = useQuery<ListLinksWithImagesQuery>(LIST_LINKS_WITH_IMAGES, {
    notifyOnNetworkStatusChange: true,
  });

  const maxLinksReached: boolean =
    (links?.length ?? 0) >= 5 && !workspace?.subscriptionType;

  useEffect(() => {
    if (error) {
      eventBusEmit({ type: "form-error", payload: error });
      setError("");
      return;
    }

    if (success)
      eventBusEmit({
        type: "form-success",
        payload: success,
      });
    setSuccess("");
  }, [success, error]);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("links.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (getMyWorkspace.data) {
      setWorkspace(getMyWorkspace.data.MyWorkspace);
    }
  }, [getMyWorkspace.data]);

  useEffect(() => {
    if (listLinks.data) {
      setLinks(listLinks.data.ListLinks);
    }
  }, [listLinks.data]);

  if (getMyWorkspace.loading) return <Loading />;
  if (getMyWorkspace.error) {
    return <PartialError error={t("links.qrcode-loading-error")} />;
  }

  const addLink = (event: React.MouseEvent) => {
    event.preventDefault();

    assignPathWith(navigate, "/workspace/links/new");
  };

  const NewLinkButton = () => {
    if (
      !permitAccess({
        role: getRole(),
        resource: Resource.DASHBOARD,
        permission: Permission.CREATE,
      })
    )
      return <></>;

    const shownButton = (
      <Button
        className="w-auto text-right"
        type="submit"
        startIcon={<AddLocationIcon />}
        sx={{ textTransform: "none" }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={addLink}
        disabled={maxLinksReached}
      >
        {t("links.add-new-link-submit")}
      </Button>
    );

    let tooltip = "";
    if (maxLinksReached) {
      tooltip =
        "You have reached the maximum number of QR Codes for your plan. Please upgrade to add more QR Codes.";
    }

    return (
      <Tooltip title={tooltip}>
        <span>{shownButton}</span>
      </Tooltip>
    );
  };

  if (!getMyWorkspace.data) {
    return <Loading />;
  }

  return (
    <Grid container spacing={3}>
      {/* Search */}
      <Grid item sx={{ textAlign: "left", flexGrow: 1 }}>
        <Box id="filter-panel" />
      </Grid>
      <Grid item xs={12} sm={5} lg={4} className="relative text-right">
        <NewLinkButton />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container>
          {/* Members */}
          <Grid item xs={12} sm={7} lg={8}>
            <Title>{t("links.qrcode-title")}</Title>
            <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
              {t("links.qrcode-description")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinksList links={links} listLinks={listLinks} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <LinkSettings
              fetchedLinkSetting={getMyWorkspace.data.MyWorkspace.linkSetting}
              setUpdateLinkSetting={setUpdateLinkSetting}
              updateLinkSetting={updateLinkSetting}
              setError={setError}
              setSuccess={setSuccess}
            />
            <CustomDomains setError={setError} setSuccess={setSuccess} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
