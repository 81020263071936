import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const env = import.meta.env.VITE_ENV;
const fallbackLng = "en";
const debug = env === "development";
const supportedLngs = ["en"]; // all languages that are supported in our system

const detection = {
  lookupQuerystring: "lang",
  lookupLocalStorage: "language",
  order: [
    "querystring",
    // "cookie",
    "localStorage",
    // "sessionStorage",
    "navigator",
    // "htmlTag",
    // "path",
    // "subdomain",
  ],
};

i18next
  .use(LanguageDetector)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng,
    debug,
    detection,
    supportedLngs,
    saveMissing: true,
    nonExplicitSupportedLngs: false, // if set to true, it supports language variation (US etc.)
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

// we adapt the HTML tag
// and set the language in case it's not done already
i18next.on("languageChanged", (lang) => {
  console.log("change language in storage : " + lang);
  localStorage.setItem("language", lang);
  document.documentElement.setAttribute("lang", lang);
});

export default i18next;
