import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { eventBusEmit } from "@/helpers/event-bus";
import Title from "@/pages/workspace/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone-esm";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const LIST_DESIGN_TEMPLATES_DEBOUNCE = 500;

const GET_LINK = gql`
  query GetLinkWithDesign($id: UUID!) {
    GetLink(id: $id) {
      id
      shortID
      destination
      entrypoint
      linkDesign {
        id
        imageSignedURL
        imageFormat
        imageSize
        dotsOptions {
          color
          type
        }
        cornersSquareOptions {
          color
          type
        }
        cornersDotOptions {
          color
          type
        }
        backgroundOptions {
          color
        }
        shape
        width
        height
        errorCorrectionLevel
        hideBackgroundDots
      }
    }
  }
`;

const UPSERT_LINK_DESIGN_TEMPLATE = gql`
  mutation UpsertLinkDesign(
    $linkID: UUID!
    $linkQRCode: Upload!
    $linkDesignInput: LinkDesignInput!
  ) {
    UpsertLinkDesign(
      linkID: $linkID
      linkQRCode: $linkQRCode
      linkDesignInput: $linkDesignInput
    ) {
      id
    }
  }
`;

export default function DesignLink() {
  const { linkID } = useParams();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Title>Design QR Code</Title>

            <Divider variant="middle" sx={{ mb: 2 }} />

            <QRCodeDesign style="containers" linkID={linkID} />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export function QRCodeDesign({
  newDesign = false,
  linkID,
  style = "containers",
  callback = () => {},
}) {
  const { t } = useTranslation(["workspace", "misc"]);
  const navigate = useNavigate();
  const [, setID] = useState<string>("");
  const [getBlob, setGetBlob] = useState<boolean>(false);
  const [blobValue, setBlobValue] = useState<Blob | null>(null);
  const [linkDesignInput, setLinkDesignInput] = useState<any>({});
  const [originLinkDesign, setOriginLinkDesign] = useState<any>({});
  const [entrypoint, setEntrypoint] = useState<string>(DEFAULT_QRCODE_URL);
  const [linkDestination, setLinkDestination] = useState<string>("");
  const getLink = useQuery(GET_LINK, {
    variables: { id: linkID },
    fetchPolicy: "no-cache",
  });
  const [mutationUpsertLinkDesign, { data, error, loading }] = useMutation(
    UPSERT_LINK_DESIGN_TEMPLATE
  );

  let containerClass = "h-fit p-5";
  if (style === "containers") {
    containerClass =
      "h-fit p-5 mt-5 border-verylightgrey border-solid border-2 border-greyish bg-white rounded-lg shadow-md";
  }

  useEffect(() => {
    const possibleErrors = error;
    const errorDisplay: string = findAndTranslateErrors({
      error: possibleErrors,
      t,
    });
    let successDisplay: string = "";
    if (data) {
      successDisplay = t("links.edit.updated");
      callback();
    }
    if (successDisplay) {
      eventBusEmit({ type: "form-success", payload: successDisplay });
    }
    if (errorDisplay) {
      eventBusEmit({ type: "form-error", payload: errorDisplay });
    }
  }, [t, data, error]);

  React.useEffect(() => {
    if (getLink.data) {
      setID(getLink.data.GetLink.id);
      setEntrypoint(getLink.data.GetLink.entrypoint);
      setOriginLinkDesign(getLink.data.GetLink.linkDesign);
      setLinkDestination(getLink.data.GetLink.destination);
      if (getLink.data.GetLink.name) {
        const pageName: string = getLink.data.GetLink.name;
        eventBusEmit({ type: "page-name", payload: pageName });
      }
    }
  }, [getLink.data, navigate, t]);

  React.useEffect(() => {
    if (blobValue) {
      // We have to do that because the input is a string
      // So we prepare them before sending it over network
      const dotsOptions = JSON.stringify(linkDesignInput.dotsOptions);
      const cornersSquareOptions = JSON.stringify(
        linkDesignInput.cornersSquareOptions
      );
      const cornersDotOptions = JSON.stringify(
        linkDesignInput.cornersDotOptions
      );
      const backgroundOptions = JSON.stringify(
        linkDesignInput.backgroundOptions
      );

      const filename = `qrcode.${blobToExtension(blobValue)}`;
      const linkQRCode = new File([blobValue], filename, {
        type: blobValue.type,
      });

      if (linkDesignInput.image) {
        fetch(linkDesignInput.image)
          .then((res) => res.blob())
          .then((blob) => {
            const filename = `image.${blobToExtension(blob)}`;
            const image = new File([blob], filename, { type: blob.type });

            mutationUpsertLinkDesign({
              variables: {
                linkID,
                linkQRCode,
                linkDesignInput: {
                  ...linkDesignInput,
                  dotsOptions,
                  cornersSquareOptions,
                  cornersDotOptions,
                  backgroundOptions,
                  image,
                },
              },
            });
            setBlobValue(null);
          });
      } else {
        // We don't want to upload an empty string
        linkDesignInput.image = null;
        mutationUpsertLinkDesign({
          variables: {
            linkID,
            linkQRCode,
            linkDesignInput: {
              ...linkDesignInput,
              dotsOptions,
              cornersSquareOptions,
              cornersDotOptions,
              backgroundOptions,
            },
          },
        });
        setBlobValue(null);
      }
    }
  }, [blobValue]);

  if (getLink.loading) return <Loading />;
  if (getLink.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    // We will try to get the blob from the QrGen
    // It'll then run the blobCallback once we have it
    setGetBlob(true);
  };

  const blobCallback = (blob) => {
    setBlobValue(blob);
    setGetBlob(false);
  };

  // it's loading and we don't have the link data yet
  // so we don't show the design
  if (!getLink.data && !getLink.error) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Stack direction={{ xs: "column", md: "row" }} sx={{ mb: 2 }} spacing={2}>
        <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
          <QRCodeGenerator
            target={entrypoint}
            getBlob={getBlob}
            containerClass={containerClass}
            blobCallback={blobCallback}
            originLinkDesign={originLinkDesign}
            setLinkDesignInput={setLinkDesignInput}
            linkDestination={linkDestination}
            newDesign={newDesign}
          />
        </Grid>
      </Stack>

      <Divider variant="middle" sx={{ mb: 2 }} />

      <Grid
        item
        xs={12}
        md={6}
        lg={3}
        sx={{ textAlign: "center", margin: "auto" }}
      >
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={handleSubmit}
          text="Save design"
          fullWidth
        />
      </Grid>
    </React.Fragment>
  );
}

export function QRCodeGenerator({
  target,
  getBlob,
  blobCallback,
  originLinkDesign,
  containerClass,
  setLinkDesignInput,
  linkDestination,
  newDesign,
}) {
  const [linkDesign, setLinkDesign] = useState(originLinkDesign);

  const DEFAULT_EXTENSION = "SVG";
  const DEFAULT_QRCODE_IMAGE = "";
  const DEFAULT_IMAGE_SIZE = 0.5;
  const DEFAULT_SIZE = 512;
  const DEFAULT_ERROR_CORRECTION_LEVEL = "Q";
  const DEFAULT_DOTS_OPTIONS = {
    color: "#000000",
    type: "square",
  };
  const DEFAULT_CORNERS_SQUARE_OPTIONS = {
    color: "#000000",
    type: "square",
  };
  const DEFAULT_CORNERS_DOT_OPTIONS = {
    color: "#000000",
    type: "square",
  };
  const BACKGROUND_OPTIONS = {
    color: "transparent",
  };
  const DEFAULT_SHAPE = "SQUARE";

  const [imageFormat, setImageFormat] = useState<string>(
    originLinkDesign?.imageFormat || DEFAULT_EXTENSION
  );

  // input on the corner
  const [inputImage, setInputImage] = useState<File | undefined>(undefined);
  const [image, setImage] = useState<string>(DEFAULT_QRCODE_IMAGE);
  const [imageSize, setImageSize] = useState<number>(
    originLinkDesign?.imageSize || DEFAULT_IMAGE_SIZE
  );
  const [hideBackgroundDots, setHideBackgroundDots] = useState<boolean>(
    originLinkDesign?.hideBackgroundDots || true
  );
  const [height, setHeight] = useState<number>(
    originLinkDesign?.height || DEFAULT_SIZE
  );
  const [width, setWidth] = useState<number>(
    originLinkDesign?.width || DEFAULT_SIZE
  );
  const [size, setSize] = React.useState<number>(
    originLinkDesign?.height || DEFAULT_SIZE
  );
  const [errorCorrectionLevel, setErrorCorrectionLevel] =
    useState<ErrorCorrectionLevel>(
      originLinkDesign?.errorCorrectionLevel || DEFAULT_ERROR_CORRECTION_LEVEL
    );
  const [dotsOptions, setDotsOptions] = useState<{
    color?: string;
    gradient?: string;
    type: DotType;
  }>(originLinkDesign?.dotsOptions || DEFAULT_DOTS_OPTIONS);
  const [cornersSquareOptions, setCornersSquareOptions] = useState<{
    color?: string;
    gradient?: string;
    type: CornerSquareType;
  }>(originLinkDesign?.cornersSquareOptions || DEFAULT_CORNERS_SQUARE_OPTIONS);
  const [cornersDotOptions, setCornersDotOptions] = useState<{
    color?: string;
    gradient?: string;
    type: CornerDotType;
  }>(originLinkDesign?.cornersDotOptions || DEFAULT_CORNERS_DOT_OPTIONS);
  const [backgroundOptions, setBackgroundOptions] = useState<{
    color?: string;
    gradient?: string;
  }>(originLinkDesign?.backgroundOptions || BACKGROUND_OPTIONS);
  const [shape, setShape] = useState<"SQUARE" | "CIRCLE">(
    originLinkDesign?.shape || DEFAULT_SHAPE
  );

  useEffect(() => {
    if (linkDesign?.imageSignedURL) {
      fetch(linkDesign.imageSignedURL)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `image.${blobToExtension(blob)}`, {
            type: blob.type,
          });
          setInputImage(file);
        });
    } else {
      setInputImage(undefined);
    }
    // The entire linkDesign must be a dependency because
    // we have to check if it has a imageSignedURL or not all the time
  }, [linkDesign?.imageSignedURL]);

  useEffect(() => {
    if (!linkDesign?.imageSignedURL) {
      setInputImage(undefined);
    }
    // The entire linkDesign must be a dependency because
    // we have to check if it has a imageSignedURL or not all the time
    // To remove it from the file input if needed
  }, [linkDesign]);

  useEffect(() => {
    setShape(originLinkDesign?.shape || DEFAULT_SHAPE);
    setWidth(originLinkDesign?.width || DEFAULT_SIZE);
    setHeight(originLinkDesign?.height || DEFAULT_SIZE);
    setImage(originLinkDesign?.imageSignedURL || DEFAULT_QRCODE_IMAGE);
    setImageSize(originLinkDesign?.imageSize || DEFAULT_IMAGE_SIZE);
    setImageFormat(originLinkDesign?.imageFormat || DEFAULT_EXTENSION);
    setDotsOptions(originLinkDesign?.dotsOptions || DEFAULT_DOTS_OPTIONS);
    setCornersSquareOptions(
      originLinkDesign?.cornersSquareOptions || DEFAULT_CORNERS_SQUARE_OPTIONS
    );
    setCornersDotOptions(
      originLinkDesign?.cornersDotOptions || DEFAULT_CORNERS_DOT_OPTIONS
    );
    setBackgroundOptions(
      originLinkDesign?.backgroundOptions || BACKGROUND_OPTIONS
    );
    setErrorCorrectionLevel(
      originLinkDesign?.errorCorrectionLevel || DEFAULT_ERROR_CORRECTION_LEVEL
    );
    setHideBackgroundDots(originLinkDesign?.hideBackgroundDots || true);
    // We don't forget that because we'll probably send it out
    setLinkDesignInput(originLinkDesign);
  }, [originLinkDesign]);

  useEffect(() => {
    setShape(linkDesign?.shape || DEFAULT_SHAPE);
    setWidth(linkDesign?.width || DEFAULT_SIZE);
    setHeight(linkDesign?.height || DEFAULT_SIZE);
    setImage(linkDesign?.imageSignedURL || DEFAULT_QRCODE_IMAGE);
    setImageSize(linkDesign?.imageSize || DEFAULT_IMAGE_SIZE);
    setDotsOptions(linkDesign?.dotsOptions || DEFAULT_DOTS_OPTIONS);
    setCornersSquareOptions(
      linkDesign?.cornersSquareOptions || DEFAULT_CORNERS_SQUARE_OPTIONS
    );
    setCornersDotOptions(
      linkDesign?.cornersDotOptions || DEFAULT_CORNERS_DOT_OPTIONS
    );
    setBackgroundOptions(linkDesign?.backgroundOptions || BACKGROUND_OPTIONS);
    setErrorCorrectionLevel(
      linkDesign?.errorCorrectionLevel || DEFAULT_ERROR_CORRECTION_LEVEL
    );
    setHideBackgroundDots(linkDesign?.hideBackgroundDots || true);
    // We don't forget that because we'll probably send it out
    setLinkDesignInput(linkDesign);
  }, [linkDesign]);

  // Every detail will be set up to be
  // transmitted to the backend upon saving
  useEffect(() => {
    setLinkDesignInput({
      imageFormat,
      image,
      imageSize,
      dotsOptions,
      cornersSquareOptions,
      cornersDotOptions,
      backgroundOptions,
      shape,
      width,
      height,
      errorCorrectionLevel,
      hideBackgroundDots,
    });
  }, [
    setLinkDesignInput,
    imageFormat,
    image,
    imageSize,
    dotsOptions,
    cornersSquareOptions,
    cornersDotOptions,
    backgroundOptions,
    shape,
    width,
    height,
    errorCorrectionLevel,
    hideBackgroundDots,
  ]);

  useEffect(() => {
    setWidth(size);
    setHeight(size);
  }, [size]);

  useEffect(() => {
    if (imageFormat === "JPEG") {
      if (backgroundOptions.color === "transparent") {
        setBackgroundOptions({
          color: "#FFFFFF",
        });
      }
    }
  }, [imageFormat]);

  return (
    <div className="grid p-3">
      <div className="justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-12">
          <div className="h-fit grid grid-cols-1">
            <QrCodeImage
              target={target}
              getBlob={getBlob}
              blobCallback={blobCallback}
              containerClass={containerClass}
              imageFormat={imageFormat}
              image={image}
              imageSize={imageSize}
              dotsOptions={dotsOptions}
              cornersSquareOptions={cornersSquareOptions}
              cornersDotOptions={cornersDotOptions}
              backgroundOptions={backgroundOptions}
              shape={shape}
              width={width}
              height={height}
              errorCorrectionLevel={errorCorrectionLevel}
              hideBackgroundDots={hideBackgroundDots}
            />
            <div className={containerClass}>
              <QualitySettings
                setSize={setSize}
                size={size}
                setImageFormat={setImageFormat}
                imageFormat={imageFormat}
                setErrorCorrectionLevel={setErrorCorrectionLevel}
                errorCorrectionLevel={errorCorrectionLevel}
              />
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-1">
            <div className={containerClass}>
              <DesignTemplates
                setLinkDesign={setLinkDesign}
                linkDestination={linkDestination}
                newDesign={newDesign}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className={containerClass}>
                <ShapeSettings setShape={setShape} shape={shape} />
                <DotsSettings
                  setDotsOptions={setDotsOptions}
                  dotsOptions={dotsOptions}
                />
                <CornersBorderSettings
                  setCornersSquareOptions={setCornersSquareOptions}
                  cornersSquareOptions={cornersSquareOptions}
                  setCornersDotOptions={setCornersDotOptions}
                  cornersDotOptions={cornersDotOptions}
                />
              </div>
              <div className="grid grid-cols-1">
                <div className={containerClass}>
                  <ImageSettings
                    setImage={setImage}
                    image={image}
                    inputImage={inputImage}
                    setInputImage={setInputImage}
                    setImageSize={setImageSize}
                    imageSize={imageSize}
                    setHideBackroundDots={setHideBackgroundDots}
                    hideBackgroundDots={hideBackgroundDots}
                    errorCorrectionLevel={errorCorrectionLevel}
                  />
                  <BackgroundSettings
                    setBackgroundOptions={setBackgroundOptions}
                    backgroundOptions={backgroundOptions}
                    imageFormat={imageFormat}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function QrCodeImage({
  target,
  getBlob,
  blobCallback,
  imageOnly = false,
  containerClass,
  imageFormat,
  image,
  imageSize,
  dotsOptions,
  cornersSquareOptions,
  cornersDotOptions,
  backgroundOptions,
  shape,
  width,
  height,
  errorCorrectionLevel,
  hideBackgroundDots,
}) {
  const QrImage = () => {
    return (
      <QrCode
        target={target}
        getBlob={getBlob}
        blobCallback={blobCallback}
        imageFormat={imageFormat}
        image={image}
        imageSize={imageSize}
        dotsOptions={dotsOptions}
        cornersSquareOptions={cornersSquareOptions}
        cornersDotOptions={cornersDotOptions}
        backgroundOptions={backgroundOptions}
        shape={shape}
        width={width}
        height={height}
        errorCorrectionLevel={errorCorrectionLevel}
        hideBackgroundDots={hideBackgroundDots}
      />
    );
  };

  if (imageOnly) {
    return <QrImage />;
  }

  return (
    <div className={containerClass}>
      <div className="mt-2 mb-2 center">
        <div className="m-auto text-center">
          <QrImage />
        </div>
      </div>
    </div>
  );
}

export function CornersBorderSettings({
  cornersSquareOptions,
  setCornersSquareOptions,
  cornersDotOptions,
  setCornersDotOptions,
}) {
  const { t } = useTranslation("workspace");

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Corners
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        {/* Outer */}
        <Grid item xs={12} md={8}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="corner-square-type"
            label={t("design.outer-shape")}
            value={cornersSquareOptions.type}
            onChange={(event) => {
              const type = event.target.value as DotType;
              setCornersSquareOptions({
                color: cornersSquareOptions.color,
                type,
              });
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="square">{t("design.square")}</option>
            <option value="dot">{t("design.dot")}</option>
            <option value="extra-rounded">{t("design.extra-rounded")}</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <ColorField
            setOptions={setCornersSquareOptions}
            options={cornersSquareOptions}
          />
        </Grid>
        {/* Inside */}
        <Grid item xs={12} md={8}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="corners-dot-options"
            label={t("design.inner-shape")}
            value={cornersDotOptions.type}
            onChange={(event) => {
              const type = event.target.value as DotType;
              setCornersDotOptions({
                color: cornersDotOptions.color,
                type,
              });
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="square">{t("design.square")}</option>
            <option value="dot">{t("design.dot")}</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <ColorField
            setOptions={setCornersDotOptions}
            options={cornersDotOptions}
          />
        </Grid>
      </Grid>
    </div>
  );
}

import { SketchPicker } from "react-color";

const ColorField = ({
  setOptions,
  options,
}: {
  options: { type: string; color?: string; gradiant?: string };
  setOptions: React.Dispatch<
    React.SetStateAction<{ type: string; color?: string; gradiant?: string }>
  >;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation("workspace");

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (color) => {
    setOptions({
      color: color.hex,
      type: options.type,
    });
  };

  const styles: { [key: string]: React.CSSProperties } = {
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: 3,
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div className="relative">
      <TextField
        type="color"
        label={t("design.color")}
        value={options.color}
        fullWidth
        onClick={handleClick}
      />
      {isOpen ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={options.color}
            onChange={handleChange}
            disableAlpha={true}
          />
        </div>
      ) : null}
    </div>
  );
};

const NoBackgroundComponent = ({ setBackgroundOptions }) => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className="mt-2">
        <div>No background</div>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setBackgroundOptions({
              color: "#FFFFFF",
            });
          }}
        >
          Add background
        </Button>
      </Grid>
    </React.Fragment>
  );
};

const SetBackgroundComponent = ({
  backgroundOptions,
  setBackgroundOptions,
  imageFormat,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <ColorField
          setOptions={setBackgroundOptions}
          options={backgroundOptions}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Tooltip
          title={
            imageFormat === "JPEG"
              ? "The JPEG format does not support transparency."
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              disabled={imageFormat === "JPEG"}
              onClick={() => {
                setBackgroundOptions({
                  color: "transparent",
                });
              }}
            >
              Remove
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </React.Fragment>
  );
};

export function BackgroundSettings({
  backgroundOptions,
  setBackgroundOptions,
  imageFormat,
}) {
  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Background
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        {backgroundOptions.color === "transparent" ? (
          <NoBackgroundComponent setBackgroundOptions={setBackgroundOptions} />
        ) : (
          <SetBackgroundComponent
            setBackgroundOptions={setBackgroundOptions}
            backgroundOptions={backgroundOptions}
            imageFormat={imageFormat}
          />
        )}
      </Grid>
    </div>
  );
}

export function QualitySettings({
  setSize,
  size,
  imageFormat,
  setImageFormat,
  errorCorrectionLevel,
  setErrorCorrectionLevel,
}) {
  const { t } = useTranslation("workspace");

  let errorCorrection: number = 0;
  switch (errorCorrectionLevel) {
    case "L":
      errorCorrection = 7;
      break;
    case "M":
      errorCorrection = 15;
      break;
    case "Q":
      errorCorrection = 25;
      break;
    case "H":
      errorCorrection = 30;
      break;
    default:
      break;
  }

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Quality
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="error-correction-level"
            label="Code complexity"
            value={errorCorrectionLevel}
            onChange={(event) => {
              const errCorrLvl = event.target.value as ErrorCorrectionLevel;
              setErrorCorrectionLevel(errCorrLvl);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="L">{t("design.low")}</option>
            <option value="M">{t("design.medium")}</option>
            <option value="Q">{t("design.high")}</option>
            <option value="H">{t("design.very-high")}</option>
          </TextField>
          <Typography variant="body2" color="textSecondary" className="mt-2">
            {t("design.error-correction", { errorCorrection })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="format"
            label="Format"
            value={imageFormat}
            onChange={(event) => {
              setImageFormat(event.target.value);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="SVG">SVG</option>
            <option value="PNG">PNG</option>
            <option value="JPEG">JPEG</option>
            <option value="WEBP">WEBP</option>
          </TextField>
          <Typography variant="body2" color="textSecondary" className="mt-2">
            This is the format that will be used when you download the QR code.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography id="size-slider" gutterBottom>
            Size
          </Typography>
          <Slider
            aria-label="Meters"
            defaultValue={size || 0}
            onChange={(event: Event, newValue: number | number[]) => {
              setSize(newValue as number);
            }}
            getAriaValueText={(value: number) => `${value}px`}
            valueLabelDisplay="auto"
            // shiftStep={25}
            // step={25}
            step={8}
            marks={marks}
            min={256}
            max={2048}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const marks = [
  {
    value: 512,
    label: "512px",
  },
  {
    value: 1024,
    label: "1024px",
  },
  {
    value: 1536,
    label: "1536px",
  },
];

const LIST_DESIGN_TEMPLATES = gql`
  query ListDesignTemplates(
    $search: String
    $size: Int
    $linkDestination: String
  ) {
    ListDesignTemplates(
      search: $search
      size: $size
      linkDestination: $linkDestination
    ) {
      bestFitID
      designTemplates {
        id
        name
        slug
        thumbnailSignedURL
        builtIn
        description
        linkDesign {
          id
          imageSignedURL
          imageFormat
          imageSize
          dotsOptions {
            color
            type
          }
          cornersSquareOptions {
            color
            type
          }
          cornersDotOptions {
            color
            type
          }
          backgroundOptions {
            color
          }
          shape
          width
          height
          errorCorrectionLevel
          hideBackgroundDots
        }
      }
    }
  }
`;

export function DesignTemplate({
  setSelected,
  selected,
  setLinkDesign,
  designTemplate,
}) {
  let style = "";
  if (selected == designTemplate.id) {
    style = "bg-grey border-greyish";
  } else {
    style = "border-transparent";
  }

  return (
    <div
      className={`${style} hover:bg-grey border-2 border-solid p-2 m-2 rounded cursor-pointer`}
      onClick={() => {
        setSelected(designTemplate.id);
        setLinkDesign(designTemplate.linkDesign);
      }}
    >
      <div className="text-lg">
        <span className="align-middle mr-2">
          <img src={designTemplate.thumbnailSignedURL} className="w-6 h-6" />
        </span>
        {designTemplate.name}
      </div>
      <div className="text-sm">{designTemplate.description}</div>
    </div>
  );
}

export function DesignTemplateList({
  search,
  setLinkDesign,
  linkDestination,
  newDesign,
}) {
  const [selected, setSelected] = useState<string | null>(null);
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  // Debounce the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, LIST_DESIGN_TEMPLATES_DEBOUNCE);

    // Cleanup timeout on search change
    return () => clearTimeout(handler);
  }, [search]);

  const size = 8;
  const variables: Record<string, any> = { size };

  if (debouncedSearch) {
    variables["search"] = debouncedSearch;
  }

  if (linkDestination) {
    variables["linkDestination"] = linkDestination;
  }

  const listDesignTemplates = useQuery(LIST_DESIGN_TEMPLATES, {
    variables,
  });

  useEffect(() => {
    if (listDesignTemplates.data) {
      // Pre-select designs only for new designs (not editing an old QR Code)
      if (!selected && newDesign) {
        const bestFit =
          listDesignTemplates.data.ListDesignTemplates.designTemplates.find(
            (designTemplate) =>
              designTemplate.id ===
              listDesignTemplates.data.ListDesignTemplates.bestFitID
          );

        if (bestFit) {
          setSelected(listDesignTemplates.data.ListDesignTemplates.bestFitID);
          setLinkDesign(bestFit.linkDesign);
        }
      }
    }
  }, [listDesignTemplates.data]);

  if (listDesignTemplates.loading)
    return (
      <div className="mt-8">
        <Loading />
      </div>
    );

  if (listDesignTemplates.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 mt-2">
      {listDesignTemplates.data.ListDesignTemplates.designTemplates.map(
        (designTemplate) => (
          <DesignTemplate
            setSelected={setSelected}
            selected={selected}
            key={designTemplate.id}
            designTemplate={designTemplate}
            setLinkDesign={setLinkDesign}
          />
        )
      )}
      {listDesignTemplates.data.ListDesignTemplates.designTemplates.length ===
        0 && <div className="mt-5">No templates found</div>}
    </div>
  );
}

export function DesignTemplates({ setLinkDesign, linkDestination, newDesign }) {
  const [search, setSearch] = useState<string>("");

  return (
    <React.Fragment>
      <div className="grid grid-col-1">
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          id="search-input"
          label="Search a template"
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <DesignTemplateList
        search={search}
        setLinkDesign={setLinkDesign}
        linkDestination={linkDestination}
        newDesign={newDesign}
      />
    </React.Fragment>
  );
}

export function ShapeSettings({ shape, setShape }) {
  const { t } = useTranslation("workspace");

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Base
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="dots-options-type"
            label={t("design.shape")}
            value={shape}
            onChange={(event) => {
              setShape(event.target.value);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="SQUARE">Square</option>
            <option value="CIRCLE">Circle</option>
          </TextField>
        </Grid>
      </Grid>
    </div>
  );
}

export function SettingOptionAndColor({
  options,
  settingOption,
  setSettingOption,
}: {
  options: string[];
  settingOption: { color?: string; gradient?: string; type: string };
  setSettingOption: React.Dispatch<
    React.SetStateAction<{
      color?: string;
      gradient?: string;
      type: string;
    }>
  >;
}) {
  const { t } = useTranslation("workspace");
  // const [gradient, setGradient] = useState<boolean>(false);

  return (
    <React.Fragment>
      {/* TODO in the future: gradient */}
      {/* <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="color-type"
            name="color-type"
            value={gradient ? "gradient" : "single"}
            onChange={(event) => {
              const value = event.target.value;
              if (value === "gradient") {
                setGradient(true);
              } else {
                setGradient(false);
              }
            }}
          >
            <FormControlLabel
              value="single"
              control={<Radio />}
              label={t("design.single-color")}
            />
            <FormControlLabel
              value="gradient"
              control={<Radio />}
              label={t("design.gradient")}
            />
          </RadioGroup>
        </FormControl>
      </Grid> */}
      <Grid item xs={12} md={8}>
        <TextField
          select
          fullWidth
          variant="outlined"
          id="dots-options-type"
          label={t("design.shape")}
          value={settingOption.type}
          onChange={(event) => {
            const type = event.target.value;
            setSettingOption({
              color: settingOption.color,
              type,
            });
          }}
          SelectProps={{
            native: true,
          }}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {t(`design.${option}`)}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <ColorField setOptions={setSettingOption} options={settingOption} />
      </Grid>
    </React.Fragment>
  );
}

export function DotsSettings({ dotsOptions, setDotsOptions }) {
  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Dots
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <SettingOptionAndColor
          options={[
            "square",
            "dots",
            "rounded",
            "extra-rounded",
            "classy",
            "classy-rounded",
          ]}
          settingOption={dotsOptions}
          setSettingOption={setDotsOptions}
        />
      </Grid>
    </div>
  );
}

export function DropzoneHelp() {
  const { t } = useTranslation("workspace");

  return (
    <React.Fragment>
      <svg
        className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
        />
      </svg>
      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
        <span className="font-semibold">{t("design.click-to-upload")}</span>{" "}
        {t("design.or-drag-and-drop")}
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-400">
        {t("design.image-formats")}
      </p>
    </React.Fragment>
  );
}

export function DropzoneDone({ uploadedImage }) {
  const { t } = useTranslation("workspace");
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (uploadedImage) {
      const objectURL = URL.createObjectURL(uploadedImage);
      setImageSrc(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    } else {
      setImageSrc("");
    }
  }, [uploadedImage]);

  return (
    <React.Fragment>
      <img src={imageSrc} alt="uploaded qr code" width="30px" />
      <p className="mb-2 text-sm text-primary text-center">
        <span className="font-semibold">{t("design.image-uploaded")}</span>
        <br />
        {t("design.click-to-replace")}
      </p>
      <p className="text-xs text-gray-500">{t("design.image-formats")}</p>
    </React.Fragment>
  );
}

export function ImageDropzone({ setInputImage, inputImage }) {
  const onDrop = React.useCallback((acceptedFiles) => {
    setInputImage(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  return (
    <div
      className="flex items-center justify-center w-full"
      {...getRootProps()}
    >
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-verylightgrey border-dashed border-greyish rounded-lg cursor-pointer bg-white hover:bg-lightgrey dark:bg-darkgrey dark:hover:bg-darker-grey dark:border-greyish dark:hover:border-darkgrey"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          {inputImage ? (
            <DropzoneDone uploadedImage={inputImage} />
          ) : (
            <DropzoneHelp />
          )}
        </div>
        <input
          {...getInputProps()}
          id="dropzone-file"
          onChange={(event) => {
            const files = event?.target?.files;
            const file = files ? files[0] : null;
            setInputImage(file);
          }}
          type="file"
          className="hidden"
        />
      </label>
    </div>
  );
}

export function ImageSettings({
  image,
  setImage,
  inputImage,
  setInputImage,
  imageSize,
  setImageSize,
  hideBackgroundDots,
  setHideBackroundDots,
  errorCorrectionLevel,
}) {
  const { t } = useTranslation("workspace");

  useEffect(() => {
    if (inputImage) {
      const objectURL = URL.createObjectURL(inputImage);
      setImage(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [inputImage]);

  const removeImage = (e) => {
    e.preventDefault();
    setInputImage(undefined);
    setImage(null);
  };

  return (
    <div className="mt-2 mb-5">
      <Typography component="h1" variant="h5">
        Image
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("design.file")}
          </Typography>
          <ImageDropzone
            setInputImage={setInputImage}
            inputImage={inputImage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            variant="outlined"
            id="image-size"
            label={t("design.size")}
            value={imageSize}
            onChange={(event) => {
              const size = event.target.value;
              setImageSize(size);
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="0.3">{t("design.very-small")}</option>
            <option value="0.4">{t("design.small")}</option>
            <option value="0.5">{t("design.medium")}</option>
            <option value="0.6">{t("design.big")}</option>
          </TextField>
          {imageSize == "0.6" ? (
            <FormWarning
              message={
                "Big images may prevent QR Code recognition in some devices. Consider rising the code complexity and test it out."
              }
              sx={{ mt: 1 }}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hideBackgroundDots}
                onChange={(event) => {
                  setHideBackroundDots(event.target.checked);
                }}
                name="hide-background-dots"
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                {t("design.hide-background")}
              </Typography>
            }
          />
          <Grid item xs={12}>
            <Typography variant="caption">
              {t("design.hide-background-desc")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {image && (
            <Button variant="contained" color="error" onClick={removeImage}>
              {t("design.remove-image")}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

import { FormWarning } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import { blobToExtension, findAndTranslateErrors } from "@/helpers/format";
import { t } from "i18next";
import QRCodeStyling, {
  CornerDotType,
  CornerSquareType,
  DotType,
  ErrorCorrectionLevel,
} from "qr-code-styling";

const DEFAULT_QRCODE_URL = "https://linkbreakers.com";

const qrGen = new QRCodeStyling({
  width: 300,
  height: 300,
  // type: "svg",
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20,
  },
});

export function QrCode({
  target,
  getBlob,
  blobCallback,
  imageFormat,
  image,
  imageSize,
  dotsOptions,
  cornersSquareOptions,
  cornersDotOptions,
  backgroundOptions,
  shape,
  width,
  height,
  errorCorrectionLevel,
  hideBackgroundDots,
}) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [update, setUpdate] = React.useState(false);

  useEffect(() => {
    if (ref.current) {
      qrGen.append(ref.current);
    }
  }, []);

  useEffect(() => {
    setUpdate(true);
  }, [
    target,
    image,
    dotsOptions,
    cornersSquareOptions,
    cornersDotOptions,
    backgroundOptions,
    width,
    height,
    shape,
    errorCorrectionLevel,
    imageSize,
    hideBackgroundDots,
  ]);

  // image margin
  const margin = 5;

  useEffect(() => {
    if (update) {
      qrGen.update({
        data: target,
        image,
        dotsOptions,
        cornersSquareOptions,
        cornersDotOptions,
        backgroundOptions,
        width,
        height,
        shape: shape.toLowerCase(),
        qrOptions: { errorCorrectionLevel },
        imageOptions: { margin, imageSize, hideBackgroundDots },
      });
      setUpdate(false);
    }
  }, [update]);

  useEffect(() => {
    if (getBlob) {
      qrGen.getRawData(imageFormat.toLowerCase()).then((data) => {
        blobCallback(data);
      });
    }
  }, [getBlob, imageFormat]);

  return (
    <div>
      {/* we need this height for when there's no image inside */}
      <div style={{ minHeight: "305px" }} ref={ref} />
    </div>
  );
}
