import { GridWithSearch } from "@/components/data-table";
import LoadingButton from "@/components/loading-button";
import { QrcodeImage } from "@/components/qr-code";
import { downloadImage, linkFileName } from "@/helpers";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import { rawURLAssign } from "@/helpers/navigation";
import { Link } from "@/interfaces";
import { dialogStyle, tableContainerSx } from "@/theme";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  Tooltip,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

export function List({ links, listLinks }) {
  const { i18n, t } = useTranslation("workspace");

  const LinkDesignCell = ({ link }: { link: Link }) => {
    if (link.designType === "BASIC") {
      return (
        <Tooltip title={t("links.design-basic-tooltip")} placement="top">
          <Chip icon={<QrCodeIcon />} label={t("links.design-basic")} />
        </Tooltip>
      );
    } else if (link.designType === "CUSTOM") {
      return (
        <Tooltip
          title="You customized the design of this QR Code"
          placement="top"
        >
          <Chip icon={<AutoAwesomeIcon />} label="Custom" />
        </Tooltip>
      );
    } else if (link.designType === "AI") {
      return (
        <Tooltip title={"AI"} placement="top">
          <Chip icon={<AutoModeIcon />} label={t("links.design-moving")} />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const CustomDomainCell = ({ customDomain }) => {
    const isCustom = customDomain?.name;
    const domainName =
      customDomain?.name || import.meta.env.VITE_API_SHORT_RAW_DOMAIN;

    return <Chip color={isCustom ? `primary` : undefined} label={domainName} />;
  };

  const columns: GridColDef[] = [
    {
      field: "qrcode",
      flex: 1,
      headerName: t("links.list.qrcode"),
      renderCell: (params: GridRenderCellParams<Link>) => {
        return (
          <QrcodeImage
            image={params.row.qrcodeSignedURL}
            fileName={linkFileName(params.row)}
            clickToDownload={false}
            size="thumbnail"
          />
        );
      },
    },
    {
      field: "name",
      flex: 1,
      headerName: t("members.list.name"),
      renderCell: (params: GridRenderCellParams<Link>) => {
        return params.row.name || "-";
      },
      valueGetter: (_, row) => {
        return row.name;
      },
    },
    {
      field: "domain",
      flex: 1,
      headerName: t("links.list.domain"),
      renderCell: (params) => {
        return <CustomDomainCell customDomain={params.row.customDomain} />;
      },
      valueGetter: (_, row) => {
        return row.customDomain?.name;
      },
    },
    {
      field: "destination",
      flex: 1,
      headerName: t("links.list.destination"),
      renderCell: (params: GridRenderCellParams<Link>) => {
        return params.row.destination;
      },
      valueGetter: (_, row) => {
        return row.destination;
      },
    },
    {
      field: "design-type",
      flex: 1,
      headerName: t("links.list.design"),
      renderCell: (params: GridRenderCellParams<Link>) => {
        return <LinkDesignCell link={params.row} />;
      },
      valueGetter: (_, row) => {
        return row.designType;
      },
    },
    {
      field: "short-link",
      flex: 1,
      headerName: t("links.list.slug"),
      renderCell: (params: GridRenderCellParams<Link>) => {
        return params.row.slug || "-";
      },
      valueGetter: (_, row) => {
        return row.slug;
      },
    },
    {
      field: "actions",
      flex: 0.6,
      headerName: "Actions",
      sortable: false,
      renderCell: (params: GridRenderCellParams<Link>) => {
        return <ActionMore link={params.row} listLinks={listLinks} />;
      },
    },
  ];

  const rows = links;
  return (
    <GridWithSearch columns={columns} rows={rows} i18n={i18n} rowHeight={80} />
  );
}

export function ActionMore({ link, listLinks }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (link: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(link.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label="More"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuAction
          link={link}
          listLinks={listLinks}
          handleClose={handleClose}
        />
      </Menu>
    </React.Fragment>
  );
}

const DELETE_LINK = gql`
  mutation DeleteLink($id: UUID!) {
    DeleteLink(id: $id)
  }
`;

export function DeleteLinkDialog({
  link,
  deleteLinkOpen,
  setDeleteLinkOpen,
  listLinks,
}) {
  const [mutationDeleteLink, deleteLink] = useMutation(DELETE_LINK);
  const handleDeleteLink: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    mutationDeleteLink({
      variables: {
        id: link.id,
      },
    });
  };

  React.useEffect(() => {
    if (deleteLink.data) {
      setDeleteLinkOpen(false);
      listLinks.refetch();
    }
  }, [deleteLink.data]);

  return (
    <Dialog
      open={deleteLinkOpen}
      TransitionComponent={Transition}
      sx={dialogStyle}
      onClose={() => {
        setDeleteLinkOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Deletion of QR Code {link.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          This action cannot be undone. All events related to this QR Code will
          be deleted as well. If it was shared publicly, the redirection will
          stop working. Are you sure you want to delete this QR Code?
        </DialogContentText>
        <LoadingButton
          style={{ marginTop: "2em" }}
          color="error"
          onClick={handleDeleteLink}
          variant="contained"
          sx={{ mr: 2 }}
          loading={deleteLink.loading}
          disabled={deleteLink.loading}
          fullWidth
          text="Confirm deletion of this QR Code"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setDeleteLinkOpen(false);
          }}
        >
          Cancel action
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import DeleteIcon from "@mui/icons-material/Delete";

export function MenuAction({ link, handleClose, listLinks }) {
  const { t } = useTranslation("workspace");
  const [deleteLinkOpen, setDeleteLinkOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <DeleteLinkDialog
        link={link}
        deleteLinkOpen={deleteLinkOpen}
        setDeleteLinkOpen={setDeleteLinkOpen}
        listLinks={listLinks}
      />
      <MenuItem
        onClick={() => {
          handleClose();
          downloadImage(link.qrcodeSignedURL, linkFileName(link));
        }}
      >
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>{t("links.download-qrcode")}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          handleClose();
          rawURLAssign(`/workspace/dashboard/links/${link.id}/edit`);
        }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>{t("links.edit-qrcode")}</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          handleClose();
          setDeleteLinkOpen(true);
        }}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Delete QR Code</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.READ,
          })
        }
        onClick={() => {
          handleClose();
          const trackingLink = link.entrypoint;
          if (!trackingLink) {
            eventBusEmit({
              type: "form-error",
              payload: "Tracking link could not be copied.",
            });
          } else {
            navigator.clipboard.writeText(trackingLink);
            eventBusEmit({
              type: "form-success",
              payload: "Tracking link copied to your clipboard!",
            });
          }
        }}
      >
        <ListItemIcon>
          <ContentCopy />
        </ListItemIcon>
        <ListItemText>Copy tracking link</ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

import { Transition } from "@/components/transition";
import { eventBusEmit } from "@/helpers/event-bus";
import { gql, useMutation } from "@apollo/client";
import ContentCopy from "@mui/icons-material/ContentCopy";

export default function LinksList({ links, listLinks }) {
  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={tableContainerSx}>
        <List links={links} listLinks={listLinks} />
      </TableContainer>
    </React.Fragment>
  );
}
