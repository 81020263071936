import { Identity } from "@/interfaces";
import { i18n } from "i18next";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { Permission, Resource, permitAccess } from "./access";

const DOMAIN = import.meta.env.VITE_BASE_DOMAIN;
// typically it'll be cookies with more info regarding the person
// that come from the landing
// we don't really want to remove those ever so that we can store
// and understand where they come from and preferences from the landing
const KEEP_COOKIES_FOREVER = ["client-origin", "client-preferences"];
const SKIP_COOKIES = ["language", "i18n.language"];

export function setLanguage(lang: string, i18n: i18n): void {
  if (lang === "system") {
    localStorage.removeItem("language");
  } else {
    localStorage.setItem("language", lang);
  }
  // this language isn't used by any of i18next
  // it's just there to return the language on places
  // where we can't have i18n loaded
  localStorage.setItem("i18n.language", i18n.language);
}

export function getLanguage(): string | null {
  return localStorage.getItem("i18n.language");
}

export function hasFake() {
  return !!getItem("fake");
}

export function setFake() {
  setItem("fake", true);
}

export function unsetFake() {
  clearItem("fake");
}

export function hasSensitiveData() {
  return !!getItem("blur");
}

export function setSensitiveData() {
  setItem("blur", true);
}

export function unsetSensitiveData() {
  clearItem("blur");
}

export function setIdentity({
  accessToken,
  workspaceID,
  role,
  id,
}: Identity): void {
  if (id) {
    setItem("id", id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.hj) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.hj("identify", id);
    }
    mixpanel.identify(id);
  }
  if (accessToken) setItem("accessToken", accessToken);
  if (role) setItem("role", role);
  if (workspaceID) setItem("workspaceID", workspaceID);
}

export function clearIdentity(): void {
  clearItems();
  mixpanel.reset();
}

export function getIdentity(): Identity {
  const id = getItem("id") as Identity["id"];
  const role = getItem("role") as Identity["role"];
  const accessToken = getItem("accessToken") as Identity["accessToken"];
  const workspaceID = getItem("workspaceID") as Identity["workspaceID"];

  return { id, accessToken, role, workspaceID };
}

export function getRole(): Identity["role"] {
  return getIdentity().role;
}

interface ClientOrigin {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  originPage: string | null;
  originReferrer: string | null;
  originVariant: string | null;
  originOffer: string | null;
  originRef: string | null;
}

export function getClientOrigin(): ClientOrigin {
  // we just want to use cookies for this one
  // since the communication is with the landing etc.
  // we don't want to spread it to sessions
  const clientOriginCookie = Cookies.get("client-origin");
  if (!clientOriginCookie) {
    return {} as ClientOrigin;
  }

  const jsonBase = atob(clientOriginCookie);
  const clientOrigin = JSON.parse(jsonBase) as ClientOrigin;

  if (getItem("originOffer")) {
    if (!clientOrigin.originOffer) {
      clientOrigin.originOffer = getItem("originOffer") || null;
    }
  }

  if (getItem("originRef")) {
    if (!clientOrigin.originRef) {
      clientOrigin.originRef = getItem("originRef") || null;
    }
  }

  return clientOrigin;
}

export function setClientOrigin(key: string, value: string): void {
  // NOTE: this is not exploitable because we don't have next available
  // once we have next we can set cookies on the backend and send out on the frontend
  // so we have an asymetric session / cookie system for no
  // TODO: Next
  // const clientOrigin = getClientOrigin();
  // // we don't override what already exists in the cookie
  // if (!clientOrigin[key]) clientOrigin[key] = value;

  // const toCookie = btoa(JSON.stringify(clientOrigin));
  // Cookies.set("client-origin", toCookie);

  setItem(key, value);
  // return clientOrigin;
}

export function hasIdentity(): boolean {
  return !isGuest();
}

export function getWorkspaceID(): string | undefined {
  return getItem("workspaceID");
}

export function withDashboardAccess(role: Identity["role"]): boolean {
  return permitAccess({
    role,
    resource: Resource.DASHBOARD,
    permission: Permission.READ,
  });
}

export function withoutDashboardAccess(role: Identity["role"]): boolean {
  return role === "USER";
}

export function isGuest(): boolean {
  if (getIdentity().accessToken === "") return true;
  if (getIdentity().accessToken === null) return true;
  if (getIdentity().accessToken === undefined) return true; // not sure why, it happens sometimes
  return false;
}

export function setItem(key: string, value) {
  localStorage.setItem(key, value);
  if (SKIP_COOKIES.includes(key)) return;
  Cookies.set(key, value, { path: "/", domain: `.${DOMAIN}` });
}

export function getItem(key: string): string | undefined {
  const storeValue = localStorage.getItem(key);
  const cookieValue = Cookies.get(key);

  if (!storeValue && cookieValue) setItem(key, cookieValue);
  if (storeValue && !cookieValue) setItem(key, storeValue);

  return storeValue || cookieValue;
}

export function clearItems(): void {
  localStorage.clear();
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    if (!KEEP_COOKIES_FOREVER.includes(cookieName)) {
      clearItem(cookieName);
    }
  });
}

export function clearItem(key: string): void {
  localStorage.removeItem(key);

  if (Cookies.get(key)) {
    Cookies.remove(key, { path: "/", domain: DOMAIN });
    Cookies.remove(key);
  }
}

export function getGlobal(key: string): string | boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.$linkbreakers) window.$linkbreakers = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.$linkbreakers[key];
}
export function setGlobal(key: string, value: string | boolean): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.$linkbreakers) window.$linkbreakers = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.$linkbreakers[key] = value;
}
