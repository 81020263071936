import GlobalLoading from "@/components/global-loading";
import { rawURLReplace } from "@/helpers/navigation";
import { GET_MY_WORKSPACE } from "@/preflight";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";

const CREATE_BILLING_SESSION = gql`
  mutation CreateBillingSession($returnURL: String!) {
    CreateBillingSession(returnURL: $returnURL)
  }
`;

export default function Billing() {
  const [noBilling, setNoBilling] = useState<boolean>(false);
  const getMyselfWorkspace = useQuery(GET_MY_WORKSPACE);
  const [mutationCreateBillingSession, { data, error }] = useMutation(
    CREATE_BILLING_SESSION
  );

  useMemo(() => {
    if (getMyselfWorkspace.data) {
      if (!getMyselfWorkspace.data.MyWorkspace.subscriptionType) {
        setNoBilling(true);
      } else {
        const returnURL = import.meta.env.VITE_API_BASE_URL;
        mutationCreateBillingSession({
          variables: { returnURL },
        });
      }
    }
  }, [getMyselfWorkspace.data]);

  // there's no billing in this case
  // so we return to the base app
  // NOTE: it can happen when someone originally
  // has a sub but doesn't have anymore
  if (noBilling) {
    rawURLReplace(import.meta.env.VITE_API_BASE_URL);
  }

  if (data) {
    rawURLReplace(data.CreateBillingSession);
  }

  return <GlobalLoading />;
}
