import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { eventBusEmit } from "@/helpers/event-bus";
import { Report } from "@/interfaces";
import ReportsList from "@/pages/workspace/dashboard/reports/list";
import { gql, useQuery } from "@apollo/client";
import PushPinIcon from "@mui/icons-material/PushPin";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../components/title";

const LIST_LINKS = gql`
  query ListLinks {
    ListLinks {
      id
      shortID
      name
      entrypoint
      destination
    }
  }
`;

export default function ListReports() {
  const { t } = useTranslation(["workspace", "misc"]);
  const [reports, setReports] = React.useState<Report[]>([]);
  const { data, error, loading } = useQuery(LIST_LINKS);
  const [links, setLinks] = useState([]);
  const [dateSelector, setDateSelector] = React.useState<string>("");
  const [dateRange, setDateRange] = React.useState({
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("reports.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    setReports([]);
  }, [dateSelector]);

  useEffect(() => {
    if (data) setLinks(data.ListLinks);
  }, [data, links]);

  if (error)
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  if (loading) return <Loading />;

  return (
    <React.Fragment>
      <DateRangeSelector
        dateSelector={dateSelector}
        setDateSelector={setDateSelector}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <Divider variant="middle" sx={{ mb: 2 }} />

      <Title>{t("links.metrics.reports-title")}</Title>
      <Typography
        justifyContent="end"
        color="text.secondary"
        sx={{ flex: 1, mb: 2 }}
      >
        {t("links.metrics.reports-description")}
      </Typography>

      <Grid container spacing={3}>
        {/* Links */}
        <Grid item xs={12}>
          <ReportsList
            links={links}
            dateRange={dateRange}
            setReports={setReports}
            reports={reports}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import { UpgradeButton } from "@/components/upgrade-button";
import { dateToReadableDayMonthYear, localeFromI18n } from "@/helpers/time";

const allowedDates = [
  "today",
  "yesterday",
  "this-week",
  "last-week",
  "2-weeks-ago",
  "this-month",
  "last-month",
  "2-months-ago",
];

const defaultDateSelector = "this-month";

export function getSearchDate() {
  const { searchParams } = new URL(window.location.href);
  return searchParams.get("date_selector") || defaultDateSelector;
}

export function DateRangeSelector({
  dateRange,
  setDateRange,
  dateSelector,
  setDateSelector,
}) {
  const { t } = useTranslation("misc");
  const [customDateDialog, setCustomDateDialog] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    updateSearchParams({
      date_selector: dateSelector,
    });
  }, [dateSelector]);

  const searchDate = getSearchDate();
  // this happens only on page load never again
  // we set the date selector no search date
  // data if it's acceptable
  React.useEffect(() => {
    if (allowedDates.includes(searchDate) && searchDate != dateSelector) {
      setDateSelector(searchDate);
    }
  }, []);

  React.useEffect(() => {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    switch (dateSelector) {
      case "today": {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "yesterday": {
        startDate.setDate(currentDate.getDate() - 1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(currentDate.getDate() - 1);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "this-week": {
        const firstDayOfTheWeek =
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7); // starts monday
        const lastDayOfTheWeek = firstDayOfTheWeek + 6;
        startDate.setDate(firstDayOfTheWeek);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(lastDayOfTheWeek);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "last-week": {
        const firstDayOfTheWeek =
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7); // starts monday
        const lastDayOfTheWeek = firstDayOfTheWeek + 6;
        startDate.setDate(firstDayOfTheWeek - 7);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(lastDayOfTheWeek - 7);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "2-weeks-ago": {
        const firstDayOfTheWeek =
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7); // starts monday
        const lastDayOfTheWeek = firstDayOfTheWeek + 6;
        startDate.setDate(firstDayOfTheWeek - 14);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(lastDayOfTheWeek - 14);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "this-month": {
        startDate.setDate(1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setMonth(currentDate.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "last-month": {
        startDate.setMonth(currentDate.getMonth() - 1, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "2-months-ago": {
        startDate.setMonth(currentDate.getMonth() - 2, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setMonth(currentDate.getMonth() - 1, 0);
        endDate.setHours(23, 59, 59, 999);

        break;
      }
      default:
        break;
    }
    // we won't set the date range if it's a custom one
    // because it means it's probably already set from another part (custom date range)
    if (dateSelector !== "custom") {
      setDateRange({
        start: startDate,
        end: endDate,
      });
    }
  }, [setDateRange, dateSelector]);

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ mb: 2 }}>
      <CustomDateDialog
        customDateDialog={customDateDialog}
        setCustomDateDialog={setCustomDateDialog}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setDateSelector={setDateSelector}
      />
      <Grid item xs={12} md={6}>
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.today")}
          color={dateSelector === "today" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("today");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.yesterday")}
          color={dateSelector === "yesterday" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("yesterday");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.this-week")}
          color={dateSelector === "this-week" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("this-week");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.last-week")}
          color={dateSelector === "last-week" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("last-week");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.2-weeks-ago")}
          color={dateSelector === "2-weeks-ago" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("2-weeks-ago");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.this-month")}
          color={dateSelector === "this-month" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("this-month");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.last-month")}
          color={dateSelector === "last-month" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("last-month");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.2-months-ago")}
          color={dateSelector === "2-months-ago" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("2-months-ago");
          }}
        />
      </Grid>
      <Grid style={{ marginLeft: "auto" }}>
        <Chip
          label={`${dateToReadableDayMonthYear(
            dateRange.start
          )} - ${dateToReadableDayMonthYear(dateRange.end)}`}
          color="primary"
          icon={<PushPinIcon />}
          onClick={() => {
            setCustomDateDialog(true);
          }}
        />
      </Grid>
    </Stack>
  );
}

import { Transition } from "@/components/transition";
import { updateSearchParams } from "@/helpers/navigation";
import { dialogStyle } from "@/theme";
import { DateRange, DateRangePicker } from "mui-daterange-picker";

export function CustomDateDialog({
  customDateDialog,
  setCustomDateDialog,
  dateRange,
  setDateRange,
  setDateSelector,
}) {
  const { t, i18n } = useTranslation("workspace");
  const locale = localeFromI18n(i18n);
  const [localDateRange, setLocalDateRange] = React.useState<DateRange>({});

  const handleClose = () => {
    setCustomDateDialog(false);
  };

  return (
    <Dialog
      maxWidth={false}
      sx={dialogStyle}
      open={customDateDialog}
      TransitionComponent={Transition}
      onClose={() => {
        setCustomDateDialog(false);
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {t("metrics.select-custom-date-range")}
      </DialogTitle>
      <DialogContent>
        <DateRangePicker
          initialDateRange={{
            startDate: dateRange.start,
            endDate: dateRange.end,
          }}
          wrapperClassName="date-range-picker-custom-class"
          maxDate={new Date()}
          open={true}
          toggle={handleClose}
          onChange={(range) => setLocalDateRange(range)}
          locale={locale}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={localDateRange === null}
          onClick={() => {
            // We set it from the beginning to the end of the days selected in the range
            localDateRange.startDate?.setHours(0, 0, 0, 0);
            localDateRange.endDate?.setHours(23, 59, 59, 999);
            setDateRange({
              start: localDateRange.startDate,
              end: localDateRange.endDate,
            });
            setDateSelector("custom");
            setCustomDateDialog(false);
          }}
          color="primary"
          variant="contained"
        >
          {t("metrics.select-custom-date-range-save")}
        </Button>
        <Button
          onClick={() => {
            setCustomDateDialog(false);
          }}
        >
          {t("metrics.select-custom-date-range-close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
